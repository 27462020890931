.staff-card {
    background-color: #fff;
    color: $body-color;
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        grid-column-gap: 8px;
        padding: 12px;

        &-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $gray-100;
            padding: 10px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-author {
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            letter-spacing: -0.02em;
            margin-bottom: 2px;
        }

        &-post {
            font-size: 12px;
            line-height: 120%;
            color: $gray-600;
        }
    }

    &__img {
        width: 100%;
        position: relative;

        @include media-breakpoint-up(lg) {
            height: 23.44vw;
        }
        @include media-breakpoint-between(sm, lg) {
            height: 40vw;
        }
        @include media-breakpoint-down(sm) {
            height: 70vw;
        }

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-like {
            position: absolute;
            background-color: $white;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            padding: 10px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: max(16px, 1.25vw);
            left: max(12px, 0.87);

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &__text {
        padding-block: max(20px, 1.87vw);
        padding-inline: max(12px, 1vw);

        p {
            font-size: 14px;
            line-height: 140%;
        }
    }
}