.services-card {
    position: relative;
    transition: background .3s;
    z-index: 1;
    overflow: hidden;
    height: 100%;
    display: block;
    background-color: $blue;
    color: #fff;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #111;
        opacity: 0;
        transition: opacity .3s;
        z-index: 0;
    }

    a {
        color: #fff;

        &:hover {
            color: $danger;
        }
        &:active {
            color: $tone-red;
        }
    }

    svg path {
        stroke: #fff;
    }

    &:hover {
        color: #fff;
        &::after {
            opacity: .14;
        }
    }

    &:hover & {
        &__link-icon {
            color: $danger;

            svg path {
                stroke: $danger !important;
            }
        }

        &__bg img {
            transform: scale(1.05);
        }
    }

    &--light {
        background-color: $gray-100 !important;
        a {
            color: $primary !important;

            &:hover {
                color: $danger !important;
            }
        }

        svg path {
            stroke: $primary !important;
        }
        &:hover {
            &::after {
                opacity: .03;
            }
        }
    }
    &--light & {
        &__title {
            color: $primary;

            &:hover {
                color: $danger;
            }
        }

        &__description {
            color: $body-color;
        }
    }

    &--full-bg {
        color: #fff !important;

        a {
            color: #fff !important;

            &:hover {
                color: $danger !important;
            }
            &:active {
                color: $tone-red !important;
            }
        }

        svg path {
            stroke: #fff !important;
        }


    }
    &--full-bg & {

        &__title {
            color: #fff !important;
            &:hover {
                color: $danger !important;
            }
        }
        &__description {
            color: #fff !important;
        }

        &__bg img {
            height: 100%;
            object-fit: cover;
        }
    }

    &--white {
        background-color: #fff;
    }

    &--white & {
        &__title {
            color: $primary;
        }

        &__description {
            color: $body-color;
        }
    }
    &--dark-blue {
        background-color: $dark-blue !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        grid-row-gap: 24px;
        min-height: 400px;
        position: relative;
        z-index: 10;
        height: 100%;

        @include media-breakpoint-up(xxl) {
            padding: 40px 40px 52px 40px;
        }

        @include media-breakpoint-down(xxl) {
            padding: 42px 32px 32px 32px;
        }

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
        }

        @include media-breakpoint-down(lg) {
            padding: 24px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: rems(32px);
        color: inherit;
        transition: .3s;

        &:hover {
            color: $danger;
        }

        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
    }

    &__title+&__description {
        margin-top: 24px;
    }

    &__description {
        font-size: rems(16px);
        line-height: 150%;
        letter-spacing: -0.02em;
        font-feature-settings: 'ss08' on;
        color: $white;

        @include media-breakpoint-up(xxl){
            width: 97%;
        }
        @include media-breakpoint-down(lg){
            br {
                display: none;
            }
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        line-height: 1;
        line-height: 150%;

        &-item {
            @include media-breakpoint-up(xxl) {
                font-size: 18px;
            }
            @include media-breakpoint-between(md, xxl){
                font-size: 16px;
            }
        }

        &-item+&-item {
            margin-top: 16px;
        }

        @include media-breakpoint-up(lg) {
            width: 90%;
        }
    }

    &__link {
        color: $white;
        position: absolute;
        right: 0;
        bottom: 0;

        @include media-breakpoint-up(xxl) {
            padding: 40px;
        }

        @include media-breakpoint-down(xxl) {
            padding: 32px;
        }

        @include media-breakpoint-down(lg) {
            padding: 24px;
        }

        &-icon {
            svg path {
                stroke: $white;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            @include media-breakpoint-up(xxl) {
                height: 56px;
                width: 56px;
            }

            @include media-breakpoint-down(xxl) {
                height: 50px;
                width: 50px;
            }

            @include media-breakpoint-down(lg) {
                height: 32px;
                width: 32px;
            }
        }

        &:hover &-icon {
            svg path {
                stroke: $danger;
            }
        }
    }

    &__bg img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        user-select: none;
        pointer-events: none;
        z-index: -1;
        transition: .5s;
        transform: scale(1);
    }

    &.special & {
        &__content {
            justify-content: space-between;

            // @include media-breakpoint-up(md) {
            //     justify-content: space-between;
            // }
            @media (max-width: 845px) {
                justify-content: unset;
            }
            @media (max-width: 425px) {
                justify-content: unset;
            }
        }
        &__btn {
            // @media (max-width: 845px) and (min-width: 641px) {
            //     font-size: 1.6vw;
            // }
        }
        &__link {
            &-icon {
                @include media-breakpoint-up(xxl) {
                    height: 64px;
                    width: 64px;
                }
    
                @include media-breakpoint-down(lg) {
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}