.history-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row-gap: 68px;
    background-color: #fff;
    overflow: hidden;
    padding-inline: 24px 30px;

    &__title {
        font-weight: 600;
        color: $body-color;
        @include media-breakpoint-up(md) {
            font-size: rems(20px);
        }
        
        @include media-breakpoint-down(md) {
            font-size: rems(18px);
        }
    }

    &__date {
        color: $gray-300;
        transition: color .3s;
        line-height: 100%;

        @include media-breakpoint-up(xl) {
            font-size: 90px;
        }

        @include media-breakpoint-down(xl) {
            font-size: 60px;
        }
    }
}