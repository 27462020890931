.form{
    &-floating{
        margin-top: 8px;
        padding-top: 18px;
    }
    &-floating>&-control{
        &:not(:placeholder-shown)~label{
            color: rgba($gray-700,.65);
            opacity: 1;
            &:after {
                display: none;
            }
        }
    }
    &-floating>&-control--textarea{
        height: unset;
    }
    &-floating>.form-control:focus~label:after{
        display: none;
    }
    &-control{
        background-color: $white;
        transition: background-color .3s, border-radius .3s;
        scrollbar-width: 0;
        &::-webkit-scrollbar{
            width: 0;
        }

        @include media-breakpoint-up(sm){

            &--textarea{
                transition: padding-top .3s;
                &:focus, &:not(:placeholder-shown){
                    padding-top: 40px !important;
                    &~label{
                        transform: scale(.85) translateY(.5rem) translateX(.15rem) !important;
                    }
                }

                &~label{
                    text-overflow: unset;
                    white-space: unset;
                }
            }
        }

        @include media-breakpoint-up(xl){
            border: none;
            border-bottom: 1px solid $gray-200;
            border-radius: 0;
            font-size: 16px;
        }
        @include media-breakpoint-down(xl){
            border: 1px solid $gray-200;
            border-radius: 24px;
            font-size: 14px;
        }

        &:hover:not(:focus, :disabled, :read-only), &.hover{
            @include media-breakpoint-up(xl){
                background-color: $gray-100;
                border-radius: 24px;
                border-color: $gray-100;
                &:placeholder-shown ~ label{
                    transform: translate(0, 0.6rem);
                }
            }
        }
        &:focus:not(:disabled, :read-only), &:focus-visible:not(:disabled, :read-only), &.focus, &:active:not(:disabled, :read-only){
            border-radius: 24px;
            border: 1px solid $light-blue;
            box-shadow: none;
            background-color: $white;
        }

        &:not(:placeholder-shown, :disabled, :focus, .focus){
            background: $gray-100;
            border-radius: 24px;
            border-color: $gray-100;
            transition: none;
        }
        &:disabled{
            color: $gray-300;
            background-color: unset;
            & ~ label{
                color: $gray-300;
            }
            &:not(:placeholder-shown){
                border: 1px solid;
                border-radius: 24px;
            }
        }
        &:read-only{
            pointer-events: none;
        }

        &.input-error {
            border: 1px solid $danger;
            background-color: $white;
            & ~ .form-invalid-feedback{
                display: block;
            }
        }

        &.input-valid {
            border: 1px solid $gray-100;
            background-color: $gray-100;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCAxLjQ4MDY1TDE2Ljk5MzQgMC41TDYuNzY5NDcgMTAuNDYwMkw3Ljc3NjA4IDExLjQ0MDhMMTggMS40ODA2NVpNMS4wMDY2MiA3LjExNjNMMCA4LjA5Njk0TDUuNTQ2MTggMTMuNUw2LjU1Mjc5IDEyLjUxOTRMMS4wMDY2MiA3LjExNjNaIiBmaWxsPSIjMjdBRTYwIi8+Cjwvc3ZnPgo=);
            background-position: calc(100% - 20px), center;
            padding-right: calc(1.5em + 0.75rem);
            background-repeat: no-repeat;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

            &:placeholder-shown ~ label {
                transform: translate(0, 0.6rem);
            }

            &:focus {
                background-color: $white;
                border-color: $primary;
                box-shadow: none;
            }
        }

    }
    input:-internal-autofill-selected{
        background-image: initial !important;
        background-color: initial !important;
    }
    &-label{
        font-size: 16px;
        &__required{
            color: $danger;
        }
    }
    &-floating>&-label{
        height: fit-content;
        line-height: 1;
        @include media-breakpoint-up(xl){
            transform: translate(-20px, 0.6rem);
        }
        @include media-breakpoint-down(xl){
            transform: translate(0, 0.6rem);
        }
    }
    &-invalid-feedback{
        display: none;
        width: 100%;
        margin-top: 2px;
        font-size: 12px;
        color: $danger;
        line-height: 1;
        padding-left: 20px;
    }

    &-check{
        padding-left: 0;
        display: flex;
        grid-column-gap: 12px;
        font-size: 16px;
        a{
            border-bottom: 1px solid;
        }

        & + &{
            margin-top: 10px;
        }

        &-input{
            width: 16px;
            height: 16px;
            font-size: 16px;
            line-height: 100%;
            transition: border-color .3s;
            flex-shrink: 0;
            border: 1px solid rgba(0, 0, 0, .25);

            &:hover:not(:disabled), &.hover{
                border-color: $primary;
            }
            &:focus, &:focus-visible, &.focus{
                border-color: $gray-300;
                box-shadow: 0 0 0 2px #99B3D8;
            }
            &:active{
                background-color: transparent;
            }
            &:disabled ~ label{
                opacity: 1 !important;
                color: $gray-300;
            }

            &[type=checkbox]{
                border-radius: 0;

                &:checked{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik00Ljg3MjY3IDcuNUgzLjY4OTQ0TDAgMy4zODY0M0wxLjE4MzIzIDIuMDY3MTdMNC4yODU3MSA1LjUxNTkzTDkuMzI2MDkgMEwxMC41IDEuMzI5NjRMNC44NzI2NyA3LjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
                    background-size: 70%;
                }
            }
            &[type=radio]{
                &:checked{
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9InBhdGgtMS1pbnNpZGUtMV8yMTkzMF81NzAwIiBmaWxsPSJ3aGl0ZSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNyAwQzMuMTM0MDEgMCAwIDMuMTM0MDEgMCA3QzAgMTAuODY2IDMuMTM0MDEgMTQgNyAxNEMxMC44NjYgMTQgMTQgMTAuODY2IDE0IDdDMTQgMy4xMzQwMSAxMC44NjYgMCA3IDBaTTEyIDdDMTIgOS43NjE0MiA5Ljc2MTQyIDEyIDcgMTJDNC4yMzg1OCAxMiAyIDkuNzYxNDIgMiA3QzIgNC4yMzg1OCA0LjIzODU4IDIgNyAyQzkuNzYxNDIgMiAxMiA0LjIzODU4IDEyIDdaIi8+CjwvbWFzaz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDBDMy4xMzQwMSAwIDAgMy4xMzQwMSAwIDdDMCAxMC44NjYgMy4xMzQwMSAxNCA3IDE0QzEwLjg2NiAxNCAxNCAxMC44NjYgMTQgN0MxNCAzLjEzNDAxIDEwLjg2NiAwIDcgMFpNMTIgN0MxMiA5Ljc2MTQyIDkuNzYxNDIgMTIgNyAxMkM0LjIzODU4IDEyIDIgOS43NjE0MiAyIDdDMiA0LjIzODU4IDQuMjM4NTggMiA3IDJDOS43NjE0MiAyIDEyIDQuMjM4NTggMTIgN1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xIDdDMSAzLjY4NjI5IDMuNjg2MjkgMSA3IDFWLTFDMi41ODE3MiAtMSAtMSAyLjU4MTcyIC0xIDdIMVpNNyAxM0MzLjY4NjI5IDEzIDEgMTAuMzEzNyAxIDdILTFDLTEgMTEuNDE4MyAyLjU4MTcyIDE1IDcgMTVWMTNaTTEzIDdDMTMgMTAuMzEzNyAxMC4zMTM3IDEzIDcgMTNWMTVDMTEuNDE4MyAxNSAxNSAxMS40MTgzIDE1IDdIMTNaTTcgMUMxMC4zMTM3IDEgMTMgMy42ODYyOSAxMyA3SDE1QzE1IDIuNTgxNzIgMTEuNDE4MyAtMSA3IC0xVjFaTTcgMTNDMTAuMzEzNyAxMyAxMyAxMC4zMTM3IDEzIDdIMTFDMTEgOS4yMDkxNCA5LjIwOTE0IDExIDcgMTFWMTNaTTEgN0MxIDEwLjMxMzcgMy42ODYyOSAxMyA3IDEzVjExQzQuNzkwODYgMTEgMyA5LjIwOTE0IDMgN0gxWk03IDFDMy42ODYyOSAxIDEgMy42ODYyOSAxIDdIM0MzIDQuNzkwODYgNC43OTA4NiAzIDcgM1YxWk0xMyA3QzEzIDMuNjg2MjkgMTAuMzEzNyAxIDcgMVYzQzkuMjA5MTQgMyAxMSA0Ljc5MDg2IDExIDdIMTNaIiBmaWxsPSJ3aGl0ZSIgbWFzaz0idXJsKCNwYXRoLTEtaW5zaWRlLTFfMjE5MzBfNTcwMCkiLz4KPC9zdmc+Cg==);
                }
            }
        }
        & &-input{
            margin-left: 0;
        }
        &-label{
            line-height: 150%;
        }
    }
    &.was-validated &{
        &-control{
            &:not(:disabled) {
                border-radius: 24px;
            }

            &:invalid{
                background-image: none;
                border: 1px solid $danger;

                &:placeholder-shown ~ label{
                    transform: translate(0, 0.6rem);

                }

                & ~ .form-invalid-feedback{
                    display: block;
                }

                &:focus{
                    box-shadow: none;
                }
                &:hover{
                    background-color: $white;
                }
            }
            &:valid{
                border: 1px solid $gray-100;
                background-color: $gray-100;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOCAxLjQ4MDY1TDE2Ljk5MzQgMC41TDYuNzY5NDcgMTAuNDYwMkw3Ljc3NjA4IDExLjQ0MDhMMTggMS40ODA2NVpNMS4wMDY2MiA3LjExNjNMMCA4LjA5Njk0TDUuNTQ2MTggMTMuNUw2LjU1Mjc5IDEyLjUxOTRMMS4wMDY2MiA3LjExNjNaIiBmaWxsPSIjMjdBRTYwIi8+Cjwvc3ZnPgo=);
                background-position: calc(100% - 20px), center;
                &:placeholder-shown ~ label{
                    transform: translate(0, 0.6rem);
                }
                &:focus{
                    background-color: $white;
                    border-color: $primary;
                    box-shadow: none;
                }
            }
            &:focus {
                &:invalid:placeholder-shown~label, &:valid:placeholder-shown~label{
                    transform: $form-floating-label-transform;
                }
            }
        }
    }

    &--no-float-mobile &{
        @include media-breakpoint-down(sm){
            &-floating{
                display: flex;
                flex-direction: column-reverse;

                &>.form-label{
                    position: relative;
                    padding: 0 !important;
                    transform: none !important;
                    text-overflow: unset;
                    white-space: unset;
                    opacity: 1 !important;
                }
            }
            &-control{
                padding: 0.375rem 0.75rem !important;
            }
            &-label{
                font-size: 14px;
            }
        }
    }

    &-file{
        margin-top: 32px;
        &__wrap {
            position: relative;
            width: fit-content;
        }
        &__label{
            cursor: pointer;
            font-weight: 600;
            color: $primary;
            font-size: 14px;
            transition: .3s;

            span{
                border-bottom: 1px dashed;
                padding-bottom: 4px;
                margin-left: 18px;
            }

            svg path{
                transition: .3s;
            }
        }
        &__wrap:hover &__label {
            color: $light-blue;
            svg path{
                fill: $light-blue;
            }
        }
        &__input{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;

            &::-webkit-file-upload-button, &::file-selector-button{
                cursor: pointer;
            }

            &~.form-invalid-feedback{
                margin-top: 10px;
                padding-left: 0;
            }
        }
        &__files{
            margin-top: 24px;
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            flex-wrap: wrap;

            li{
                padding: 8px 12px;
                display: flex;
                align-items: center;
                grid-column-gap: 8px;
                background: #E9F2F5;
                border-radius: 24px;
                font-size: 14px;

                .delete-btn{
                    cursor: pointer;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjc3NDk0NyAwLjVMNS4wNzQxMiA0Ljc5OTE4TDkuMjI1MDUgMC42NDgyNDhMOS44NTE3NSAxLjI3NDk1TDUuNzAwODIgNS40MjU4OEwxMCA5LjcyNTA1TDkuMzczMyAxMC4zNTE4TDUuMDc0MTIgNi4wNTI1OEwwLjYyNjY5OSAxMC41TDAgOS44NzMzTDQuNDQ3NDIgNS40MjU4OEwwLjE0ODI0OCAxLjEyNjdMMC43NzQ5NDcgMC41WiIgZmlsbD0iIzAwMkY2QyIvPgo8L3N2Zz4K);
                    width: 10px;
                    height: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: .3s;

                    &:hover{
                        opacity: .6;
                    }
                }
            }
        }
    }

    &-field-checks + &-field-checks {
        margin-top: 50px;
    }

    .form-field .form-control.input-valid {
        padding-right: 45px;
    }
}


@media screen and (max-width: 845px) {

    .form-floating>.form-label {
        padding: 22px 20px 19px 20px;
    }

}
