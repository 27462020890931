.steps-section {
    &__container {
        @include media-breakpoint-up(lg) {
            padding-bottom: 104px;
            padding-top: 104px;
        }

        @include media-breakpoint-between(md, lg) {
            padding-bottom: 64px;
            padding-top: 64px;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 56px;
            padding-top: 56px;
        }
    }
    &__list {
        gap: 24px 0;
        counter-reset: olCounter;
        margin: 56px -12px 0 -12px;

        @include media-breakpoint-down(lg) {
            gap: 30px 0;
        }

        @include media-breakpoint-down(md){
            margin-top: 40px;
        }
    }
    &__item {
        padding: 0 12px;
        &-text {
            line-height: 1.5;
            @include media-breakpoint-up(md) {
                font-size: 20px;
            }
        
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }
        &-text + .btn {
            margin-top: 24px;
        }
        &::before {
            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xxl) {
            padding-right: 3%;
        }
        @include media-breakpoint-down(sm) {
            padding-right: 20%;
        }
    }
}