.learn-card {
    display: block;
    border-bottom: 1px solid $gray-200;
    padding-block: 32px;

    &__date {
        font-size: rems(16px);
        line-height: 150%;
        color: $body-color;
        @include media-breakpoint-up(md){
            display: flex;
            flex-direction: column;
        }
    }

    &__title {
        margin-bottom: 16px;
        font-weight: 600;
        transition: .3s;

        @include media-breakpoint-up(lg) {
            font-size: rems(32px);
        }

        @include media-breakpoint-between(sm, lg) {
            font-size: 25px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }

    &:hover &__title {
        color: $danger;
    }

}