@import 'swiper/swiper-bundle.min';
.swiper {
    height: fit-content;
    &-buttons {
        display: flex;
        grid-column-gap: 10px;
    }

    &-button {

        &-next,
        &-prev {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 1px solid $gray-200;
            color: $primary;
            font-size: 26px;
            transition: .3s;
            background-color: transparent;
            margin-top: 0;
            @extend %icomoon;

            &::before {
                @extend .icon-slider_arrow;
            }

            &::after {
                display: none;
            }

            &:hover {
                background-color: $gray-100;
                border-color: $gray-100;
            }

            &:active {
                background-color: $gray-200;
                border-color: $gray-200;
            }
        }

        &-prev {
            &::before {
                transform: scale(-1);
            }
        }
    }

    &-pagination {
        position: relative;

        &-progressbar {
            border-radius: 0;
            background-color: #CACACA;

            &-fill {
                background-color: $body-color !important;
            }
        }

        &-progressbar.swiper-pagination-horizontal {
            height: 1px;
        }

        &-fraction {
            width: fit-content;
            white-space: nowrap;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #8F8F8F;

        }

        &-lock {
            display: none !important;
        }
    }

    &-slide {
        height: unset;
    }

    &-controls {
        display: flex;
        align-items: center;
        grid-column-gap: 2.125vw;
        margin-top: 3.125vw;
    }

    &-controls &-pagination-fraction {
        margin-right: 10px;
    }
}