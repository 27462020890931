.accordion {
    &-item {
        border-bottom: 1px solid $gray-200;
    }

    &-item:first-child &-button {
        padding-top: 10px;
    }

    &-button {
        line-height: 1;
        text-shadow: 0 0 0 $white;
        color: $body-color;
        justify-content: space-between;
        &:not(.collapsed) {
            color: $body-color;
        }

        @media (max-width: 425px) {
            font-size: 16px;
        }

        &__icon {
            border: 1px solid $gray-200;
            border-radius: 50%;
            transform: rotate(-90deg);
            transition: transform 1s;
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;

            @media (min-width: 426px) {
                height: 48px;
                width: 48px;
            }
            @media (max-width: 425px) {
                height: 30px;
                width: 30px;
            }

            &::after, &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary;
            }

            &::after {
                width: 20px;
                height: 1.78px;
                opacity: 1;
                transition: background-color 1s, opacity 1s;
            }

            &::before {
                width: 1.78px;
                height: 20px;
            }
        }

        &[aria-expanded=true] {
            text-shadow: -0.3px -0.3px 0 $primary,
                0.3px -0.3px 0 $primary,
                -0.3px 0.3px 0 $primary,
                0.3px 0.3px 0 $primary;
            padding-bottom: 16px;
            color: $primary;

            
        }
        &[aria-expanded=true] &__icon {
            transform: rotate(90deg);
    
            &::after {
                opacity: 0;
            }
        }

        &::after{
            display: none;
        }
    }

    &-body {
        padding-top: 0;

        @include media-breakpoint-up(md){
            padding-right: 10%;
        }

        p {
            font-size: rems(20px);

            @media (max-width: 425px) {
                font-size: 16px;
            }
        }
    }
}