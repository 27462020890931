.projects-section {
    @include media-breakpoint-up(lg) {
        margin-top: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        margin-top: 64px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 56px;
    }

    &__item {
        @include media-breakpoint-up(md) {
            margin-bottom: 24px;
        }

        @include media-breakpoint-down(md) {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }

        .project-card {
            max-height: unset;
            height: 100%;

            @include media-breakpoint-up(md) {
                min-height: 425px;
            }

            &__body {
                @include media-breakpoint-up(md) {
                    padding: 3.5vw 2.5vw;
                }

                @include media-breakpoint-down(md) {
                    padding: 3.5vw 2.5vw 10vw;
                }
            }

            &__img {
                &:not(&--placeholder) {
                    &::before {
                        opacity: 1;
                    }
                }
            }

            &__title {
                margin-bottom: 17px;

                @include media-breakpoint-up(lg) {
                    width: 95%;
                }
            }

            &__text {
                font-size: rems(16px);
                line-height: 150%;

                @include media-breakpoint-up(lg) {
                    width: 93%;
                    opacity: 0;
                    transition: opacity .3s;
                }
            }

            &__btn {
                @include media-breakpoint-up(lg) {
                    opacity: 0;
                    transition: opacity .3s;
                }
            }

            &:hover .project-card__text,
            &:hover .project-card__btn {
                opacity: 1;
            }
        }
    }
}