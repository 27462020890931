.language-switcher{
    &__wrap{
        display: flex;
        align-items: center;
        grid-column-gap: 11px;
        background-color: #fff;
        @include media-breakpoint-down(lg){
            cursor: pointer;
        }
    }
    &__close{
        display: none;
        i{
            pointer-events: none;
        }
    }
    @include media-breakpoint-down(lg){
        &:not(.show) &__icon{
            display: none;
        }
        width: 23px;
    }

    &__items{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        grid-column-gap: 16px;
        color: $primary;
        font-size: rems(14px);
        overflow: hidden;

        @include media-breakpoint-down(lg){
            font-size: 16px;
        }
    }
    &__item{
        transition: .3s;
        &.current{
            order: -1;
        }
        &:not(.current){
            max-width: 0;
            margin-left: -11px;
            @include media-breakpoint-up(lg){
                cursor: pointer;
            }
        }
        a{
            text-decoration: none;
            display: block;
            height: 100%;
        }
    }
    &:hover &__item:not(.current), &:focus &__item:not(.current), &:focus-within &__item:not(.current){
        max-width: 30px;
        margin-left: 0;
    }
    &:hover &__item.current, &:focus &__item.current, &:focus-within &__item.current{
        color: $danger;
    }
    @include media-breakpoint-down(lg){
        &__close{
            background: none;
            border: none;
            font-size: 32px;
            color: $gray-300;
            padding: 16px 24px;
        }
        &.show &__wrap{
            position: absolute;
            top: 0;
            left: 0;
            padding: 20px 24px;
            width: 100%;
            z-index: 1;
            grid-column-gap: 19px;
            cursor: default;
            transition: .3s;
        }
        &.show &__item:not(.current){
            margin-left: 0;
            max-width: unset;
            cursor: pointer;
        }
        &.show &__item.current{
            cursor: default;
            color: $danger;
        }
        &.show &__close{
            display: block;
            position: absolute;
            right: 0;
        }
    }
}

.header--sticky .language-switcher {
    width: 55px;
    position: relative;
    &__items{
        flex-direction: column;
        position: absolute;
        right: 0
    }
    &__item:not(.current){
        max-height: 0;
    }
    &:hover .language-switcher__item:not(.current){
        max-height: 30px;
    }
}