@import 'history-card';

.history-section {
    border-bottom: 3px solid $gray-100;

    @include media-breakpoint-up(lg) {
        padding-bottom: 100px;
    }

    @include media-breakpoint-down(lg) {
        padding-bottom: 64px;
    }

    &__slider {
        @include media-breakpoint-up(lg) {
            margin-top: 56px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 48px;
        }
    }

    &__slide {
        &:not(:last-child) {
            border-right: 1px solid $gray-200;
        }
        &:first-child {
            .history-card {
                padding-left: 0;
            }
        }
    }
    &__slide.swiper-slide-active & {
        &__card {

            .history-card__date {
                color: $primary;
            }
        }
    }
}