.dropdown {
    width: fit-content;

    &-toggle {
        @extend %icomoon;
        min-width: 185px;
        width: 100%;
        justify-content: space-between;
        position: relative;

        span {
            font-family: 'GrtskPeta', sans-serif;
        }

        &::before {
            @extend .icon-slider_arrow;
            transform: rotate(90deg);
            order: 12;
            color: $primary;
            font-size: 14px;
            transition: transform .3s;
        }

        &::after {
            display: none;
        }

        &:hover,
        &:active,
        &.show {
            background-color: $white !important;
            color: $body-color !important;
        }

        &.show {
            border-radius: 24px 24px 0 0;
            border-bottom-color: transparent;
            box-shadow: none !important;

            &::before {
                transform: rotate(-90deg);
            }
        }

        &:disabled {
            border-color: $gray-200;

            &::before {
                color: $gray-200;
            }
        }

        &.has-checked {
            padding: 7px 18px 7px 7px;
        }
    }

    &-info {
        position: relative;
        background: #fff;
        left: 1px;
        border-radius: 25px;
        height: calc(100% - 2px);
        justify-content: space-between;
        align-items: center;
        font-size: rems(16px);
        display: none;
        width: 100%;
        grid-column-gap: 10px;

        &.show {
            display: flex;
        }

        &__left {
            display: flex;
            align-items: flex-end;
        }

        &__badge {
            padding: 8px 12px 10px;
            width: 93px;
            height: 34px;
            background: $light-blue;
            border-radius: 20px;
            color: #fff;
            width: fit-content;
            order: -1;
        }

        &__dots {
            color: $light-blue;
            letter-spacing: -0.02em;
            margin-left: 8px;
        }

        &__right {
            display: flex;
            align-items: center;
            grid-column-gap: min(10%, 17px);
        }

        &__count {
            display: none;
            color: #fff;
            background-color: $light-blue;
            padding: 4px 10px;
            border-radius: 16px;

            &.show {
                display: block;
            }
        }

        &__clear {
            @extend %icomoon;

            &::before {
                @extend .icon-close;
                font-size: 16px;
                color: $gray-300;
                transform: rotate(90deg);
                display: block;
                transition: .3s;
            }

            &:hover::before {
                color: $primary;
            }
        }
    }

    &-menu {
        font-size: 16px;
        width: 100%;
        min-width: unset;
        z-index: 90;
        padding-right: 4px;

        &::before {
            content: '';
            height: 1px;
            width: calc(100% - 48px);
            position: absolute;
            top: 0;
            left: 24px;
            display: block;
            background-color: transparent;
            transition: .3s;
        }

        &.show {
            border-radius: 0 0 24px 24px;
            border: solid 1px $light-blue;
            border-top: none;
            background-color: #fff;
            margin-top: -1px;

            &::before {
                background-color: $gray-200;
            }
        }


    }

    &-list {
        padding-left: 0;
        margin-bottom: 0;
        max-height: 145px;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;

            &-track {
                background: $white;
            }

            &-thumb {
                background: $gray-300;
                border-radius: 3px;
            }
        }
    }

    &-item {
        padding: 5px 24px;
        line-height: 1;
        cursor: pointer;
        position: relative;

        &__check {
            min-height: unset;

            label {
                white-space: normal;

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    z-index: 0;
                    cursor: pointer;
                }
            }
        }

        &:hover,
        &:active {
            background-color: transparent;
            color: $body-color;
        }
    }

    &.readonly {
        pointer-events: none;
    }

    &.hover & {
        &-toggle {
            border-color: $primary;
        }
    }

    &.focus & {
        &-toggle {
            box-shadow: 0 0 0 3px #99b3d8;
        }
    }
}