.cards-section {
    &__item {
        position: relative;
        overflow: hidden;


        &-content {
            width: 100%;
            
            @include media-breakpoint-up(md) {
                min-height: 620px;
                display: flex;
                align-items: center;
            }

            @media (min-width: 1800px) {
                width: 100%;
                max-width: 1920px;
                margin-left: auto;
                margin-right: auto;
            }

            &-wrap {
                
                @include media-breakpoint-up(md){
                    padding-block: 80px;
                    width: 50%;
                }

                @include media-breakpoint-down(md) {
                    padding-block: 9.375vw;
                }

                @include media-breakpoint-down(xl) {
                    padding-inline: 24px;
                }
            }

            h2,
            .h2 {
                margin-bottom: 44px;
            }

            .lead {
                margin-bottom: 1.5em;
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }
            }

            hr {
                opacity: .3;

                @include media-breakpoint-up(xl) {
                    margin-block: 44px;
                }
                @include media-breakpoint-down(xl) {
                    margin-block: 25px;
                }
            }
        }

        &-img {


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                height: 100%;
                width: 50%;
            }
        }

        &-btn {
            margin-top: 44px;
        }

        &.contrast {
            background-color: $primary;
            color: $white;
        }

        &.contrast & {
            &-content {

                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3,
                h4,
                .h4,
                h5,
                .h5,
                h6,
                .h6,
                .lead {
                    color: inherit;
                }
            }

            &-btn {
                color: $white;

                svg path {
                    stroke: $white;
                }
            }
        }

        &.reverse & {
            &-content {

                &-wrap {

                    @include media-breakpoint-up(xxl) {
                        padding-left: 4.625vw;
                    }

                    @include media-breakpoint-between(xl, xxl) {
                        padding-inline: 40px;
                    }

                    @include media-breakpoint-up(md) {
                        margin-left: auto;
                    }
                }
            }

            &-img {
                left: 0;
            }
        }

        &:not(.reverse) & {
            &-content {
                &-wrap {
                    @include media-breakpoint-up(xxl) {
                        padding-left: 80px;
                    }

                    @include media-breakpoint-between(xl, xxl) {
                        padding-left: 40px;
                    }
                    @include media-breakpoint-up(xl) {
                        padding-right: 77px;
                    }
                }
            }
            &-img {
                right: 0;
            }
        }
    }
}