.icons-section {
    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    &__item {
        flex: 0 0 auto;

        width: calc(11.11% - 24px);
        margin-top: 48px;
        min-width: 80px;

        img {
            height: 64px;
            width: 64px;
        }
    }
}