.main-filter {
    border-bottom: 1px solid $gray-100;
    padding-bottom: 18px;
    padding-top: 48px;

    &--hidden {
        border: none;
        padding: 0;
    }

    &--gray {
        background-color: $gray-100;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &__search {
        margin-bottom: 45px;

        .form-search{
            &__wrap{
                position: relative;
            }
            &__suggest{
                width: calc(100% + 2px);
                left: -1px;
            }
            &__row{
                &:hover{
                    border-color: $light-blue;
                }
            }
        }
        &.filled{
            .form-search__row{
                border-color: $light-blue;
            }
        }
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-column-gap: max(3vw, 15px);
        grid-row-gap: 25px;

        @media (max-width: 425px) {
            flex-direction: column;
            align-items: unset;
        }

        &-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            grid-column-gap: max(3vw, 15px);
            grid-row-gap: 15px;
        }
    }

    &__item {
        &-radio {
            &-btns {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                grid-column-gap: max(3vw, 15px);
                grid-row-gap: max(3vw, 15px);

                @media (min-width: 426px) {
                    margin-bottom: -18px;
                }
            }

            &-button {
                label {
                    cursor: pointer;
                    padding-block: 23px;
                    position: relative;
                    color: $primary;
                    transition: .3s;
                    user-select: none;

                    &:hover {
                        color: $danger;
                    }
                }

                input:checked~label {
                    pointer-events: none;
                    &::after {
                        content: '';
                        height: 3px;
                        width: 100%;
                        display: block;
                        background-color: $danger;
                        position: absolute;
                        bottom: 0;
                    }
                }

                
            }

            &-link {
                padding-block: 23px;
                color: $primary;
                transition: .3s;
                &.state-active {
                    pointer-events: none;
                    position: relative;
                    &::after {
                        content: '';
                        height: 3px;
                        width: 100%;
                        display: block;
                        background-color: $danger;
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }

        &-select {
            @media (max-width: 425px) {
                width: 100%;

                .dropdown {
                    width: 100%;
                }
            }
        }

    }

    &--gray & {
        &__list {
            &-group {
                grid-column-gap: 16px;

                @include media-breakpoint-down(md) {
                    width: 100%
                }
            }
        }
        &__item {
            width: 328px;
            flex-shrink: 0;
            max-width: calc(33% - 8px);

            @include media-breakpoint-down(md) {
                width: calc(50% - 8px);
                max-width: calc(50% - 8px);
            }

            @media (max-width: 425px) {
                width: 100%;
                max-width: 100%;
            }

            .dropdown {
                width: 100%;

                &-toggle:not(:hover, :active, .show) {
                    background-color: $white;
                    border-color: $gray-200;
                }
            }
        }
        
    }
}



// TAGS at /mediacenter/articles/ page
.articles-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;
}

.articles-tag {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 24px;
    margin-left: 9px;
    margin-bottom: 9px;

    font-size: 16px;
    font-weight: 400;
    color: #002f6c;
    background: white;

    border: 1px solid #002f6c;

    transition: all .3s;
}

.articles-tag:hover,
.articles-tag.active {
    transition: all .3s;
    background: #002f6c;
    color: white;

}

@media screen and (max-width: 991px) {
    .articles-tag {
        font-size: 14px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
}