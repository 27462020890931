.partners-section {
    @include media-breakpoint-up(xxl){
        padding-block: 104px 89px;
    }
    @include media-breakpoint-between(md, xxl){
        padding-block: 86px;
    }
    @include media-breakpoint-between(sm, md){
        padding-block: 64px;
    }
    @include media-breakpoint-down(sm){
        padding-block: 48px;
    }

    &__grid {
        @include media-breakpoint-up(xxl) {
            margin-top: 65px;
        }

        @include media-breakpoint-between(lg, xxl) {
            margin-top: 45px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 35px;
        }
    }

    &__item {
        padding: 12px;

        @include media-breakpoint-down(sm) {
            .news-card {
                &__img {
                    height: 108px;
                    width: 100%;

                    img.contain {
                        max-width: 70%;
                        max-height: 50%;
                    }
                }
            }
        }
    }
}