.news-card{
    display: block;
    &__img{
        background-color: $gray-100;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1.72 / 1;
        
        &.contain{
            img{
                max-width: 55%;
                max-height: 55%;  
            }
        }
        &:not(.contain){
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__title{
        font-weight: 600;
        line-height: 120%;
        padding-top: 24px;
        color: $body-color;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        
        @include media-breakpoint-up(xxl){
            width: 94%;
            font-size: 24px;
        }
        @include media-breakpoint-between(md, xxl){
            font-size: 20px;
        }
        @include media-breakpoint-between(sm,md){
            font-size: 18px;
        }
        @include media-breakpoint-down(sm){
            font-size: 20px;
        }
    }
    &__text{
        font-size: 16px;
        margin-bottom: 0;
        color: $body-color;
        line-height: 150%;
        letter-spacing: -0.32px;

        @include media-breakpoint-up(xxl){
            margin-top: 16px;
        }
        @include media-breakpoint-between(xl, xxl){
            margin-top: 12px;
        }
        @include media-breakpoint-down(xl){
            font-size: 14px;
            margin-top: 8px;
        }
    }
    &__showcounter-wrap {
        display: flex;
        align-items: center;
    }
    &__date {
        display: block;
        margin-top: 16px;
        font-size: 14px;
        color: $gray-600;
        line-height: 130%;
        
        @include media-breakpoint-down(xl){
            margin-top: 8px;
        }
    }
    &__showcounter {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-left: 16px;
        font-size: 14px;
        color: $gray-600;
        line-height: 130%;
        
        @include media-breakpoint-down(xl){
            margin-top: 8px;
        }
        &::before {
            content: '';
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-image: url('/frontend/build/assets/img/icons/showcounter.svg');
            background-size: 100%;
        }
    }
    &__readtime {
        margin-top: 8px;
        font-size: 14px;
        color: $gray-600;
        line-height: 130%;
    }
    &__tags {
        height: fit-content;
        margin-top: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    &__tag {
        padding: 9px 24px;
        -webkit-border-radius: 24px;
        border-radius: 24px;
        margin-right: 9px;
        margin-bottom: 9px;
        font-size: 16px;
        font-weight: 400;
        color: #002f6c;
        background: #fff;
        border: 1px solid #002f6c;
        -webkit-transition: all .3s;
        transition: all .3s;

        &_active {
            color: #fff;
            background: #002f6c;
        }

        &:last-of-type {
            margin-left: 0;
        }
    }
}
a.news-card .news-card {
    &__title {
        color: $primary;
        transition: .3s;
    }
}
a.news-card:hover .news-card {
    &__title {
        color: $danger;
    }
}
a.news-card:active .news-card {
    &__title {
        color: $tone-red;
    }
}