.typography-content {

    img {
        max-width: 100%;

        @include media-breakpoint-up(md) {
            margin-block: 56px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
    }

    li:not(.breadcrumbs__item)+li:not(.breadcrumbs__item) {
        margin-top: rems(16px);
    }

    p {
        margin-bottom: 32px;
    }

    h1, .h1 {
        margin-block: 56px;
    }

    h2, .h2 {
        margin-top: 56px;
        margin-bottom: 32px;
    }

    h3, .h3 {
        margin-top: 56px;
        margin-bottom: 24px;
    }

    h4, .h4 {
        margin-block: 32px;
    }

    h5,
    .h5,
    h6,
    .h6 {
        margin-block: 24px;
    }

    ul,
    ol {
        margin-bottom: 32px;
        font-size: rems(16px);
    }

    blockquote{
    
        h4, .h4{
            margin-top: 30px;
            margin-bottom: 13px;
        }
    }
}
section.typography-content{
    @include media-breakpoint-up(lg) {
        margin-top: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        margin-top: 64px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 56px;
    }
}