@import "@fancyapps/ui/dist/fancybox";

.fancybox{
    &__content {
        max-width: min(97%, 1760px);
        
        @include media-breakpoint-up(md){
            min-width: 668px;
        }
        @include media-breakpoint-between(sm, md){
            min-width: 560px;
        }
        @include media-breakpoint-up(xl){
            padding: 56px 40px;
        }
        @include media-breakpoint-down(xl){
            padding: 40px 32px;
        }
        @include media-breakpoint-down(sm){
            width: 100%;
        }

        // @media (min-width: 700px) {
        //     min-width: 670px; 
        // }
        @include media-breakpoint-between(sm, lg) {
            min-width: 345px;
        }
        :focus:not(.carousel__button.is-close){
            outline: none;
        }
        >.carousel__button.is-close{
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMyLjM3OCAzMy44MTgyTDE5Ljk5MTQgMjEuNDM5NEw3LjYwNDkxIDMzLjgxODJMNi4xODE4OCAzMi4zOTYxTDE4LjU2ODQgMjAuMDE3M0w2LjE4MTg4IDcuNjM4NDdMNy42MDQ5MSA2LjIxNjM1TDMzLjgxODMgMzIuNDEzM0wzMi4zNzggMzMuODE4MlpNMjEuNDA4NyAxNy4xNTg2TDMyLjM3OCA2LjE4MTgxTDMzLjgwMSA3LjYwMzkzTDIyLjgzNDYgMTguNTYwNkwyMS40MDg3IDE3LjE1ODZaIiBmaWxsPSIjMDAyRjZDIi8+Cjwvc3ZnPgo=);
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(0deg);
            transition: transform .3s;

            &:hover{
                transform: rotate(90deg);
            }
            
            @include media-breakpoint-up(md){
                width: 40px;
                height: 40px;
                top: 24px;
                right: 24px;
            }
            @include media-breakpoint-down(md){
                width: 24px;
                height: 24px;
                top: 16px;
                right: 16px;
            }
            svg{
                display: none;
            }
        }

        &--large{
            @include media-breakpoint-up(md){
                padding: max(56px,6.5vw) max(40px, 5.625vw);
            }
        }

        @include media-breakpoint-down(lg){
            .form-agree {
                width: 236px;
                max-width: 100%;
            }
        }
        
    }
    
}
