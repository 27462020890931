.reflect-x {
    transform: scaleX(-1);
}

.reflect-y {
    transform: scaleY(-1);
}

html {
    scroll-behavior: smooth;
    border: none !important;
    font-size: $base-fontsize;

    @include media-breakpoint-down(xxl) {
        font-size: 18px;
    }
}

body{
    overflow-x: hidden;
}

.container {
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(xxl) {
        padding-left: 80px;
        padding-right: 80px;
        max-width: 1920px;
    }

    @include media-breakpoint-between(xl, xxl) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media-breakpoint-down(xl) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.containered {
    @include media-breakpoint-up(xxl) {
        padding-inline: calc((100vw - 1760px) / 2);
    }

    @media (max-width: 1920px) {
        padding-inline: 80px;
    }

    @include media-breakpoint-between(xl, xxl) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media-breakpoint-down(xl) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

section, .section {
    @include media-breakpoint-up(xxl) {
        margin-top: 124px;
    }

    @include media-breakpoint-down(xxl) {
        margin-top: 96px;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 80px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 56px;
    }

    &:not(.background-animate--visibility) {
        z-index: 2;
        position: relative;
    }

    &.background+&.background {
        margin-top: 0;
    }
}

.root-wrapper>section, .root-wrapper>.section {
    background-color: #fff;
}

.section {
    &-title {
        b {
            color: $danger;
        }
    }

    &-description {
        margin-top: 18px;
        margin-bottom: 0;
    }
}

ul {
    font-size: rems(16px);
    list-style: square;
    padding-left: 15px;
    line-height: 150%;

    li {
        &::marker {
            color: $danger;
            font-size: 12px;
        }
    }
}

ol {
    font-size: rems(16px);
    list-style-type: none;
    counter-reset: olCounter;
    position: relative;
    line-height: 150%;

    &>li {
        letter-spacing: -0.02em;

        &::before {
            content: counter(olCounter);
            counter-increment: olCounter;
            color: $gray-300;
            position: absolute;
            left: 0;
        }

        &:nth-child(-n+9)::before {
            content: "0" counter(olCounter);
        }
    }

    &.digits {
        padding-left: 0;
        &>li {
            &::before {
                font-size: 56px;
                font-family: 'SsDigits';
                color: $danger;
                position: relative;
                line-height: 1;

                @include media-breakpoint-down(md) {
                    font-size: 40px;
                }
            }

            &:nth-child(-n+9)::before {
                content: counter(olCounter);
            }
        }
    }
}

li > ul, li > ol {
    margin-top: 0.8rem;
}

hr {
    @include media-breakpoint-up(lg) {
        margin-block: 32px 24px;
    }
}

.bordered-section {
    border-bottom: 3px solid $gray-200;

    @include media-breakpoint-up(lg) {
        padding-bottom: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        padding-bottom: 64px;
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 56px;
    }
}

a {
    transition: .3s;
    text-decoration: none;
    color: $light-blue;

    &:hover,
    &.hover {
        color: $danger;
    }

    &:active,
    &.active {
        color: $tone-red;
    }

    &.disabled {
        color: $gray-300;
        pointer-events: none;
    }
}

[class*="text-"] {

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: inherit;
    }

    a:not(.btn) {
        color: inherit;

        &:hover {
            color: $danger;
        }
    }
}

.text-primary a:not(.btn) {
    color: $light-blue !important;

    &:hover,
    &.hover {
        color: $danger  !important;
    }

    &:active,
    &.active {
        color: $tone-red  !important;
    }

    &.disabled {
        color: $gray-300  !important;
    }
}

.with-icon {
    display: flex;

    img, svg {
        height: 24px;
        width: 24px;
        margin: 0 0 0 10px !important;
        transition: .3s;
    }
    &:focus, &.focus{
        img, svg{
            opacity: 0;
        }
    }
}

blockquote{
    border-left: 2px solid $danger;
    padding-left: max(15px, 2vw);
    margin-block: 58px;

    h4, .h4{
        margin-top: 30px;
        margin-bottom: 13px;
    }

    @include media-breakpoint-up(md){
        width: 72%;
    }
}
b, strong {
    font-weight: 600;
}
.accent {
    color: $danger;
}