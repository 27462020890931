.action-section {
    &__wrap {
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        grid-row-gap: 40px;

        @include media-breakpoint-up(sm) {
            padding: 3.5vw;
        }

        @include media-breakpoint-down(sm) {
            padding: 24px;
        }

        h2,
        .h2 {
            margin-bottom: 16px;

            @include media-breakpoint-up(lg) {
                font-size: rems(32px);
            }
        }
    }

    >.container {
        @include media-breakpoint-down(sm) {
            padding-inline: 0;
        }
    }

    &__btn {
        &-description {
            text-align: right;
            font-size: rems(12px);

            p {
                font-size: rems(12px);
            }
        }
    }

    &__content &__btn {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-column-gap: 24px;
        grid-row-gap: 10px;

        &-description {
            text-align: left;
            @include media-breakpoint-up(sm) {
                font-size: rems(16px);
            }

            @include media-breakpoint-down(sm) {
                font-size: rems(14px);
            }

            p {
                @include media-breakpoint-up(sm) {
                    font-size: rems(16px);
                }

                @include media-breakpoint-down(sm) {
                    font-size: rems(14px);
                }
            }
        }
    }

    &__networks {
        display: flex;
        align-items: center;
        font-size: rems(16px);
        grid-column-gap: 8px;
        flex-wrap: wrap;

        &-list {
            margin-bottom: 0;
            padding-left: 0;
            margin-left: -5px;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            grid-column-gap: 5px;
            grid-row-gap: 5px;
        }

        &-item {
            a {
                display: block;
                background: transparent;
                padding: 5px;
                border-radius: 5px;
                transition: .3s;

                &:hover {
                    background: $primary;
                }
            }

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}