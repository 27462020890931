.error-page{
    position: relative;
    background: none !important;

    @include media-breakpoint-up(xxl){
        margin-bottom: 152px;
    }
    @include media-breakpoint-between(lg, xxl){
        margin-bottom: 100px;
    }
    @include media-breakpoint-between(md, lg){
        margin-bottom: 64px;
    }
    @include media-breakpoint-down(md){
        margin-bottom: 56px;
    }

    &__content{

        @include media-breakpoint-up(lg){
            width: 50%;
        }
        @include media-breakpoint-between(md, lg){
            width: 70%;
        }

        p{
            @include media-breakpoint-up(md){
                font-size: 16px;
                margin-block: 32px;
            }
            @include media-breakpoint-down(md){
                font-size: 14px;
                margin-block: 20px;
            }
        }

        .btn{
            color: $light-blue;
            &:hover{
                color: #fff;
            }
        }
        h1, .h1{
            @include media-breakpoint-up(xxl){
                font-size: 90px;
            }
        }
    }
    &__bg{
        position: absolute;
        right: 0;
        user-select: none;
        pointer-events: none;
        z-index: -1;
        @include media-breakpoint-up(xxl){
            bottom: -152px;
            height: calc(100% + 152px + 124px);
        }
        @include media-breakpoint-between(lg, xxl){
            bottom: -100px;
            height: calc(100% + 100px + 100px);
        }
        @include media-breakpoint-between(md, lg){
            bottom: -64px;
            height: calc(100% + 64px + 64px);
        }
        @include media-breakpoint-down(md){
            display: none;
        }

        img{
            height: 100%;
        }
    }
}