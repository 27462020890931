.vacancy-card {
    display: block;
    color: $body-color;
    background-color: $white;
    transition: .3s;

    &--background {
        background-color: $gray-100;
    }

    &:not(&--background) {
        border-bottom: 1px solid $gray-200;
    }

    &:not(&--background) & {
        &__wrap {
            @include media-breakpoint-up(md) {
                padding-block: max(24px, 3vw);
            }
        }
        &__icon{
            opacity: 0;
        }
    }

    &:not(&--background) &:not(&__container) & {
        &__wrap {
            @include media-breakpoint-up(md) {
                padding-inline: max(32px, 2.5vw);
            }
        }
    }

    &__wrap {
        width: 100%;
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 24px;
            padding-inline: 32px;
            grid-column-gap: 20px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 15px;
            padding-inline: 15px;
        }
    }

    &__container & {
        &__wrap {
            padding-inline: 0;
            grid-column-gap: 0;
        }
    }

    &__title {
        font-weight: 600;
        margin-bottom: 8px;
        color: inherit;
        @include media-breakpoint-up(md) {
            font-size: rems(20px);
        }
        
        @include media-breakpoint-down(md) {
            font-size: rems(18px);
        }
    }

    &__container &__title {
        @include media-breakpoint-up(sm) {
            font-size: rems(23px);
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 8px;
        align-items: center;
        font-size: rems(16px);
        color: $gray-600;
        transition: .3s;

        span {
            &:not(:last-child) {
                padding-right: 11px;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: $gray-600;
                    transition: .3s;
                }
            }
        }
    }

    &__icon {
        
        @include media-breakpoint-up(sm) {
            width: 44px;
            height: 44px;
        }

        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
            margin-left: auto;
        }

        svg{
            width: 100%;
            height: 100%;
            fill: none;
        }
    }

    &__city {
        @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 120%;
        }
    }

    &:hover {
        background-color: $primary;
        color: $white;
        border-color: transparent;
    }

    &:hover & {
        &__info {
            color: $white;

            span {
                &:not(:last-child) {
                    &::after {
                        background-color: $white;
                    }
                }
            }
        }

        &__icon {
            opacity: 1;
            svg path {
                stroke: #fff;
            }
        }
    }
}