.result-card {
    padding-block: 32px 4px;
    border-bottom: 1px solid $gray-200;

    &__wrap {
        @include media-breakpoint-up(xl) {
            width: 57%;
        }
        @include media-breakpoint-between(lg, xl) {
            width: 70%;
        }
    }

    &__link {
        color: $body-color;

        &:hover {
            color: $body-color;
        }
    }

    &__link:hover &__title {
        color: $danger;
    }

    &__title {
        margin-bottom: 16px;
        transition: .3s;
        @include media-breakpoint-up(md) {
            font-size: rems(20px);
        }
        
        @include media-breakpoint-down(md) {
            font-size: rems(18px);
        }
    }

    &__description {
        font-size: rems(16px);
    }

    &__breadcrumbs {
        margin-top: 16px;

        .breadcrumbs {
            &__link {
                font-size: 14px;
            }
        }
    }

    .accent{
        color: $danger;
        font-weight: 600;
    }
}