.learn-info {
    font-size: rems(16px);
    color: $gray-600;
    line-height: 150%;
    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-column-gap: 16px;

        &__item {
            &:not(:last-child) {
                border-right: 1px solid $gray-300;
                padding-right: 16px;
            }
        }
    }

    &__format {
        padding-left: 14px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            display: block;
            background-color: $gray-300;
            top: 50%;
            transform: translateY(-50%);
        }

        &--online {
            &::before {
                background-color: #4AC400;
            }
        }

        &--offline {
            &::before {
                background-color: #C40000;
            }
        }
    }
}