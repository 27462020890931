.results-section {
    &--background {
        $borderColor: rgba($gray-200, .2) !global;
    }

    &:not(&--background) {
        $borderColor: $gray-200 !global;
    }

    color: $primary;

    &--background {
        color: #fff;

        h2 {
            color: #fff;
        }

        @include media-breakpoint-up(xxl) {
            padding-block: 124px 30px;
        }

        @include media-breakpoint-between(xl, xxl) {
            padding-block: 104px 7px;
        }

        @include media-breakpoint-between(sm, xl) {
            padding-block: 64px 50px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 48px;
        }
        
        &.results-section--partnership {
            @include media-breakpoint-up(xl) {
                padding-block: 104px;
            }
    
            @include media-breakpoint-between(sm, xl) {
                padding-bottom: 64px;
            }
    
            @include media-breakpoint-down(sm) {
                padding-bottom: 48px;
            }
            
        }
    }

    &__text {
        color: $body-color;

        @include media-breakpoint-up(xxl) {
            margin-top: 56px;
            margin-bottom: 100px;
        }

        @include media-breakpoint-between(lg, xxl) {
            margin-top: 40px;
            margin-bottom: 64px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 24px;
            margin-bottom: 56px;
        }

        @include media-breakpoint-up(lg){
            h3, .h3{
                font-size: rems(32px);
            }
        }
        @media (max-width: 640px) {
            h3, .h3{
                br{
                    display: none;
                }
            }
        }
    }

    &--background &__text {
        color: #fff;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        &-content {
            height: 100%;

            @include media-breakpoint-up(sm) {
                border-right: 1px solid $borderColor;
                padding-right: 3em;
            }
        }

        &-head {
            margin-bottom: 32px;
            line-height: 1;
            display: flex;
            grid-column-gap: 2em;
            width: fit-content;

            @include media-breakpoint-between(sm, xxl) {
                margin-bottom: 16px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 8px;
            }

            span {
                font-size: 8em;
                letter-spacing: -0.04em;
                white-space: nowrap;
            }

            sup {
                font-size: 1.6em;
                top: 1em;
            }

            &--accent{
                color: $danger;
            }
        }

        &-text {
            font-size: 20px;
            line-height: 130%;

            @include media-breakpoint-down(xxl) {
                font-size: 16px;
            }

            @include media-breakpoint-down(xl) {
                font-size: 14px;
            }
        }
    }

    &--universal &__item, &--partnership &__item{
        &-content {
            line-height: 150%;
        }

        &-head {
            margin-bottom: 24px;

            @include media-breakpoint-between(sm, xxl) {
                margin-bottom: 16px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 8px;
            }
        }

        &-text {
            font-size: 16px;

            @include media-breakpoint-down(xl) {
                font-size: 14px;
            }
        }
    }

    &--small {
        margin-top: 40px;

        & + section, & + .section {
            margin-top: 25px;
        }
    }

    &--small &__item {
        &-content {
            line-height: 150%;
            @include media-breakpoint-up(sm) {
                padding-right: 0.6875vw;
            }
        }

        &-head {
            grid-column-gap: .6vw;

            @include media-breakpoint-up(sm) {
                margin-bottom: 16px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 8px;
            }

            sup {
                font-size: rems(16px);
            }
        }

        &-text {
            font-size: 14px;
            line-height: 150%;
        }
    }

    &--background & {
        &__item {
            &-content {
    
                @include media-breakpoint-up(sm) {
                    border-color: rgba($borderColor, .2);
                }
            }
        }
        &__grid {
            @include media-breakpoint-up(xxl) {
                margin-top: 100px;
            }

            @include media-breakpoint-between(xl, xxl) {
                margin-top: 80px;
            }

            @include media-breakpoint-between(sm, xl) {
                margin-top: 56px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
        }
    }

    .section-title{
        width: 75%;
    }

    &--partnership {
        background-color: $primary;
        background-image: url('/frontend/_dev/assets/img/today-bg.jpg');
        background-position: center;
        background-size: cover;
    }

    &--partnership & {
        &__grid {
            margin-top: 56px;

            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }
        }

        &__item-head span {
            font-size: 8.2em;
        }
    }

    &__bottom-description {
        margin-top: 15px;
        width: 854px;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__item {
            padding-bottom: 80px;
            font-size: 20px;
            padding-left: 3%;
            position: relative;

            &-head {
                sup {
                    font-size: rems(32px);
                }
            }
        }

        &__grid>&__item {

            &:nth-child(n+4) {
                padding-top: 3.8em;
                font-size: 12px;
            }

            &:nth-child(3n+1) {
                padding-left: 0;
                width: 31%;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 8rem);
                    max-width: calc(1920px - 8rem);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }

            &:nth-child(3n+2) {
                width: 37%;
            }

            &:nth-child(3n+3) {
                width: 32%;
            }

            &:nth-last-child(-n+3),
            &:nth-last-child(-n+2),
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        &--background &__grid>&__item::after {
            opacity: .2;
        }

        &__grid>&__item:nth-child(3n+3) &__item-content,
        &__grid>&__item:last-child &__item-content {
            border-right: none;
        }

        &--universal &__item, &--partnership &__item {
            font-size: 11px;
            padding-bottom: 2.3em;
        }

        &--universal &__grid>&__item, &--partnership &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 11px;
                padding-top: 2.3em;
            }
        }

        &--small &__item {
            font-size: 6px;
            padding-bottom: 2.33vw;
            padding-left: 2.08vw;
        }
        &--small &__grid>&__item {
            padding-left: 1.5625vw;

            &:nth-child(n+4) {
                font-size: 6px;
                padding-bottom: 2.33vw;
                padding-top: 0;
            }

            &:nth-child(3n+1) {
                width: 16.6%;
                
                &::after {
                    display: none;
                }
            }

            &:nth-child(3n+2) {
                width: 16.6%;
            }

            &:nth-child(3n+3) {
                width: 16.6%;
            }
            &:nth-child(6n+1) {
                padding-left: 0;
                position: relative;

                &::after {
                    content: "";
                    height: 1px;
                    width: calc(100vw - 8rem);
                    max-width: calc(1920px - 8rem);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    opacity: .2;
                }
            }
            &:nth-child(n+7){
                padding-top: 2.33vw;
            }

            &:nth-last-child(-n+6),
            &:nth-last-child(-n+5),
            &:nth-last-child(-n+4),
            &:nth-last-child(-n+3),
            &:nth-last-child(-n+2),
            &:last-child {
                padding-bottom: 0;

                &::after {
                    display: none;
                }
            }
        }

        &--small &__grid>&__item &__item-content {
            padding-bottom: 2.33vw;
            padding-top: 2.91vw;
        }

        &--small &__grid>&__item:nth-child(3n+3) &__item-content {
            border-right: 1px solid $borderColor;
        }
        &--small &__grid>&__item:nth-child(6n+6) &__item-content {
            border-right: none;
            padding-right: 0;
        }
        &--background.results-section--small &__grid>&__item:nth-child(3n+3) &__item-content {
            border-color: rgba($borderColor, .2);
        }

        &--partnership &__item {
            width: 25%;
            padding-left: min(48px, 3.5%);

            &-head {
                sup {
                    font-size: rems(20px);
                }
            }

            &:nth-child(3n+1) {
                padding-left: min(48px, 3.5%);
                width: 25%;

                &::after {
                    display: none;
                }
            }
            &:nth-child(4n+1) {
                padding-left: 0;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 8rem);
                    max-width: calc(1920px - 8rem);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }

            &:nth-child(3n+2) {
                width: 25%;
            }

            &:nth-child(3n+3) {
                width: 25%;
            }
            &:nth-last-child(-n+4) {
                &::after {
                    display: none;
                }
            }
        }
        &--partnership &__item:nth-child(4n+4) &__item-content {
            border-right: none !important;
        }
        &--partnership &__grid>&__item:nth-child(4) {
            padding-top: 0;
        }
        &--partnership &__grid>&__item:nth-child(3n+3) &__item-content {
            border-right: 1px solid rgba($borderColor, .2);
        }
    }

    @include media-breakpoint-between(xl, xxl) {
        &__item {
            font-size: .7vw;

            &-head {
                sup {
                    font-size: 1.2vw;
                }
            }
        }

        &__grid>&__item {
            &:nth-child(n+4) {
                font-size: .5vw;
            }

            &:nth-child(3n+1) {
                &::after {
                    width: calc(98vw - 48px);
                }
            }
        }

        &--universal &__item {
            font-size: .65vw;
        }

        &--universal &__grid>&__item {
            &:nth-child(n+4) {
                font-size: .65vw;
            }
        }

        &--small &__item {
            font-size: 0.35vw;
        }

        &--small &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 0.35vw;
            }
            &:nth-child(6n+1) {

                &::after {
                    width: calc(98vw - 48px);
                }
            }
        }

        &--partnership &__item {
            padding-left: 3%;
            &-head {
                sup {
                    font-size: 1.4vw;
                }
            }

            &:nth-child(3n+1) {
                padding-left: 3%;
            }
        }

    }

    @include media-breakpoint-between(lg, xl) {
        &__item {
            font-size: .7vw;
            padding-bottom: 55px;

            &-head {
                sup {
                    font-size: 1.4vw;
                }
            }
        }

        &__grid>&__item {
            &:nth-child(n+4) {
                font-size: .5vw;
            }

            &:nth-child(3n+1) {
                &::after {
                    width: calc(98vw - 48px);
                }
            }
        }

        &--universal &__item, &--partnership &__item {
            font-size: .65vw;
        }

        &--universal &__grid>&__item, &--partnership &__grid>&__item {
            &:nth-child(n+4) {
                font-size: .65vw;
            }
        }

    }

    @include media-breakpoint-between(md, lg) {
        &__item {
            padding-left: 3%;
        }
        &:not(&--universal) &__item:first-child &__item-text, &:not(&--universal) &__item:nth-child(2) &__item-text {
            width: 70%;
        }
    }

    @include media-breakpoint-between(sm, lg) {
        &__item {
            font-size: 1.27vw;
            padding-bottom: 30px;
            width: 33.3%;
            padding-left: 24px;
            position: relative;

            &-content {
                padding-top: 20px;
            }

            &-head {
                sup {
                    font-size: 1.8vw;
                }
            }
        }

        &__grid>&__item {

            &:nth-child(n+3) {
                padding-top: 30px;
                font-size: .65vw;
            }

            &:first-child {
                width: 50%;
                padding-left: 0;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 48px);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }

            &:nth-child(2) {
                width: 50%;
            }

            &:nth-child(3n+3) {
                padding-left: 0;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 48px);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }

            &:nth-last-child(-n+3),
            &:nth-last-child(-n+2),
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        &--background &__grid>&__item {
            &:first-child::after, &:nth-child(3n+3)::after {
                opacity: .2;
            } 
            
        }

        &__grid>&__item:nth-child(2) &__item-content,
        &__grid>&__item:nth-child(3n+5) &__item-content,
        &__grid>&__item:last-child &__item-content {
            border-right: none;
        }

        &__grid>&__item:first-child &__item-content,
        &__grid>&__item:nth-child(2) &__item-content {
            padding-bottom: 25px;
            padding-top: 0;
        }

        &--universal &__item, &--partnership &__item {
            font-size: 1vw;
        }

        &--universal &__grid>&__item &__item-content, &--partnership &__grid>&__item &__item-content {
            border-right: 1px solid $borderColor;
        }
        &--background.results-section--universal &__grid>&__item &__item-content, &--background.results-section--partnership &__grid>&__item &__item-content {
            border-color: rgba($borderColor, .2);
        }

        &--universal &__grid>&__item:nth-child(2n) &__item-content,
        &--universal &__grid>&__item:last-child &__item-content,
        &--partnership &__grid>&__item:nth-child(2n) &__item-content,
        &--partnership &__grid>&__item:last-child &__item-content {
            border-right: none;
        }

        &--universal &__grid>&__item, &--partnership &__grid>&__item {
            width: 50%;

            &:nth-child(3n+3) {
                padding-left: 24px;

                &::after {
                    display: none;
                }
            }

            &:nth-child(2n+3),
            &:nth-child(3) {
                padding-left: 0;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 48px);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }

            &:nth-last-child(-n+2),
            &:last-child {
                &::after {
                    display: none !important;
                }
            }
            &:nth-last-child(-n+3){
                &::after{
                    display: block;
                }
            }

            &:nth-child(n+3) {
                font-size: 1vw;
            }
        }
        &--partnership &__grid>&__item:nth-last-child(-n+3)::after {
            display: none;
        }

        &--background.results-section--universal &__grid>&__item, &--background.results-section--partnership &__grid>&__item {
            &:nth-child(2n+3),
            &:nth-child(3) {
                &::after {
                    opacity: .2;
                }
            }
            // &:nth-child(3n+3)::after {
            //     display: none;
            // }
        }


        &--small &__item {
            font-size: 0.7vw;
        }

        &--small &__grid>&__item &__item-content {
            border-right: 1px solid $borderColor;
        }
        &--background.results-section--small &__grid>&__item &__item-content {
            border-color: rgba($borderColor, .2);
        }

        &--small &__grid>&__item:nth-child(3n+3) &__item-content,
        &--small &__grid>&__item:last-child &__item-content {
            border-right: none;
        }

        &--small &__grid>&__item:nth-child(3) &__item-content {
            padding-bottom: 25px;
            padding-top: 0;
        }

        &--small &__grid>&__item {
            width: 33.3%;

            &:nth-child(3n+3) {
                padding-left: 24px;

                &::after {
                    display: none;
                }
            }

            &:nth-child(3n+4),
            &:nth-child(4) {
                padding-left: 0;

                &::after {
                    content: '';
                    height: 1px;
                    width: calc(100vw - 48px);
                    background-color: $borderColor;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }
            &:nth-child(3) {
                padding-top: 0;
            }

            &:nth-last-child(-n+3),
            &:nth-last-child(-n+2),
            &:last-child {
                &::after {
                    display: none !important;
                }
            }

            &:nth-child(n+3) {
                font-size: 0.7vw;
            }
        }
        &--background.results-section--small &__grid>&__item {
            &:nth-child(3n+4),
            &:nth-child(4) {
                &::after {
                  opacity: .2;
                }
            }
        
            
        }
        
    }

    @include media-breakpoint-down(sm) {
        &__item {
            font-size: 7px;
            padding-block: 24px;
            border-bottom: 1px solid $borderColor;
            width: 100%;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
        &--background &__item {
            border-color: rgba($borderColor, .2);
        }
    }

    @media (max-width: 1300px) and (min-width: 1200px) {
        &--universal &__item, &--partnership &__item {
            font-size: 8px;
        }

        &--universal &__grid>&__item, &--partnership &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 8px;
            }
        }
    }

    @media (max-width: 1200px) and (min-width: 1025px) {
        &--small &__item {
            font-size: 5px;
            &-text{
                font-size: 12px;
            }
        }

        &--small &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 5px;
            }
        }
    }
    @media (max-width: 1760px) and (min-width: 1441px) {
        &__item {
            font-size: 0.7vw;
        }

        &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 0.5vw;
            }
        }
        &--universal &__item, &--partnership &__item {
            font-size: 0.65vw;
        }

        &--universal &__grid>&__item, &--partnership &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 0.65vw;
            }
        }

        &--small &__item {
            font-size: 0.3vw;
        }

        &--small &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 0.3vw;
            }
        }
    }

    @media (max-width: 425px) {
        &__item {
            font-size: 1.6vw;
        }

        &__grid>&__item {

            &:nth-child(n+3) {
                font-size: 1.6vw;
            }
        }
        &--universal &__item, &--partnership &__item {
            font-size: 1.6vw;
        }

        &--universal &__grid>&__item,  &--partnership &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 1.6vw;
            }
        }

        &--small &__item {
            font-size: 1.6vw;
        }

        &--small &__grid>&__item {
            &:nth-child(n+4) {
                font-size: 1.6vw;
            }
        }
    }
}