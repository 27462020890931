.video-section{
    &__block{
        
        position: relative;

        @include media-breakpoint-up(md){
            height: 50.625vw;
        }
         @include media-breakpoint-down(md) {
            height: max(56.25vw, 310px);
        }
    }

    &__player{
        height: 100%;
    }

    &__preview{
        position: absolute;
        inset: 0;
        transition: .3s;

        &-img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-description{
            position: absolute;
            inset: 0;
            padding-block: 60px;
        }

        &-text{
            @include media-breakpoint-up(md){
                width: 45%;
            }
        }

        &-play{
            border-radius: 50%;
            transition: .3s;
            position: absolute;

            i {
               font-size: 16px; 
            }

            @include media-breakpoint-up(sm){
                height: 80px;
                width: 80px;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);
                &:hover{
                    transform: translate(50%, -50%) scale(1.2);
                }
            }
            @include media-breakpoint-down(sm){
                height: 50px;
                width: 50px;
                right: 24px;
                bottom: 24px;
            }
        }

        &.hide{
            opacity: 0;
            pointer-events: none;
            z-index: -1;
        }
    }
}