.news-detail {
    margin-top: 32px;
    border-bottom: 3px solid $gray-100;
    padding-bottom: 56px;

    &__header {
        border-bottom: 3px solid $gray-100;

        margin-bottom: 56px;

        @include media-breakpoint-up(md) {
            padding-bottom: 48px;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
    }

    &__date,
    &__update,
    &__readtime,
    &__showcounter {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #8F8F8F;
        margin-bottom: 16px;
        margin-right: 16px;
    }
    &__showcounter {
        margin-right: 0;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-image: url('/frontend/build/assets/img/icons/showcounter.svg');
            background-size: 100%;
        }
    }

    &__tags {
        height: fit-content;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    &__tag {
        padding: 9px 24px;
        -webkit-border-radius: 24px;
        border-radius: 24px;
        margin-right: 9px;
        margin-bottom: 9px;
        font-size: 16px;
        font-weight: 400;
        color: #002f6c;
        background: #fff;
        border: 1px solid #002f6c;
        -webkit-transition: all .3s;
        transition: all .3s;

        &_active {
            color: #fff;
            background: #002f6c;
        }

        &:last-of-type {
            margin-left: 0;
        }
    }

    &__title {
        @include media-breakpoint-up(xl) {
            width: 70%;
            font-size: 65px;
        }

        @include media-breakpoint-between(lg, xl) {
            width: 80%;
            font-size: 50px;
        }

        @include media-breakpoint-between(md, lg) {
            font-size: 50px;
        }
    }

    &__info {
        margin-top: 32px;

        &-content {
            display: flex;
            align-items: center;
            grid-column-gap: 55px;
            font-size: 16px;
            letter-spacing: -0.02em;
            color: #8F8F8F;
            flex-wrap: wrap;

            b {
                color: $body-color;
            }
        }
    }

    &__type {
        display: flex;
        align-items: center;
        grid-column-gap: 15px;

        &-icon {
            height: 48px;
            width: 48px;
            background: $gray-100;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: $primary;
        }
    }

    &__btn {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &__content {
        img {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                margin-block: 24px 56px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }

        li+li {
            margin-top: rems(16px);
        }

        p {
            margin-bottom: 32px;
        }

        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5 {
            margin-top: 56px;
            margin-bottom: 32px;
            color: $primary;
        }

        ul,
        ol {
            margin-bottom: 32px;
            font-size: rems(16px);
        }

        h6,
        .h6 {
            margin-bottom: 8px;
            margin-top: 40px;
            color: $body-color;
            font-size: .8rem;
        }
        .table_wrap, .table-responsive {
            margin-bottom: 32px;
        }
    }

    &+section, &+.section {
        margin-top: 76px;
    }

    &__scrolltop {
        width: 50px;
        height: 50px;
        background-color: #ff0000;
        border-radius: 100%;
        position: fixed;
        bottom: 12px;
        right: 12px;
        z-index: 5000;
        font-family: 'icomoon'!important;
        transform: rotate(-90deg);
        color: white;
        cursor: pointer;
        

        display: none;

        &::before {
            content: "\e90a";
        }

        &_active {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: showScrollButton .3s;
        }
    }
}
@keyframes showScrollButton {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}