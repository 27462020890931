.services-tabs {
    &__description {
        margin-bottom: 56px;


        &:not(&--normal) {
            color: $primary;

            @include media-breakpoint-up(xl) {
                font-size: rems(24px);
            }

            @include media-breakpoint-between(md, xl) {
                font-size: 20px;
            }

            @include media-breakpoint-between(sm, md) {
                font-size: 18px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

            p {
                @include media-breakpoint-up(xl) {
                    font-size: rems(24px);
                }

                @include media-breakpoint-between(md, xl) {
                    font-size: 20px;
                }

                @include media-breakpoint-between(sm, md) {
                    font-size: 18px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }
        }

        h3 {
            margin-top: 48px;
        }

        &:not(&--no-accent) {

            h1,
            .h1,
            h2,
            .h2 {
                b {
                    color: $danger;
                }
            }
        }
        .col-12:not(:last-child) {
            @include media-breakpoint-down(lg) {
                margin-bottom: 22px;
            }
        }

        &--normal {
            .col-12:not(:last-child) {
                &>.state-services-tabs {
                    position: sticky;
                    top: 90px;
                }
            }
        }
    }

    &__block {
        position: relative;

        @include media-breakpoint-up(xxl) {
            padding: 5.5vw 80px 40px;
        }
        @include media-breakpoint-between(xl, xxl) {
            padding: 5.5vw 40px 40px;
        }
    
        @include media-breakpoint-between(sm, xl) {
            padding: 5.5vw 24px 40px;
        }
        @include media-breakpoint-down(sm) {
            padding: 5.5vw 0 40px;
        }

        &-wrap {
            @include media-breakpoint-down(md) {
                position: relative;
                display: flex;
                flex-direction: column;
            }
        }

        &-bg {

            @include media-breakpoint-up(md) {
                position: absolute;
                user-select: none;
                pointer-events: none;
                z-index: -1;
                height: 100%;
                width: 51%;
                right: 49%;
                top: 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-link {
            position: relative;
            cursor: pointer;
            transition: opacity .6s;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: $primary;

            @media (min-width: 1800px) {
                padding-left: 78px;
                width: 100%;
                max-width: 1760px;
                margin-left: auto;
                margin-right: auto;
            }

            @media (max-width: 1799px) and (min-width: 1441px) {
                padding-left: 78px;
                width: 48%;
            }

            @include media-breakpoint-between(xl, xxl) {
                padding-left: 70px;
                width: 48%
            }

            @include media-breakpoint-up(xxl) {
                font-size: 32px;
                margin-bottom: 40px;
            }

            @include media-breakpoint-between(md, xxl) {
                padding-left: 50px;
                width: 48%;
                font-size: 20px;
                margin-bottom: 30px;
            }

            @include media-breakpoint-up(md) {
                opacity: .65;
            }

            @include media-breakpoint-down(md) {
                border-top: 1px solid #dedede;
                padding-block: 30px;
                width: calc(100% - 48px);
                margin-inline: 24px;

                br {
                    display: none;
                }

                &:first-of-type {
                    border-top: none;
                }
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
                padding-block: 24px;
            }

            &:hover {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                }
            }

            &.active {
                @include media-breakpoint-up(md) {
                    opacity: 1;
                }
            }

            &.active & {
                @include media-breakpoint-up(md) {
                    &-icon {
                        svg {
                            transform: translateX(0);
                            opacity: 1;
                        }
                    }
                }

                @include media-breakpoint-down(md) {
                    &-circle {
                        transform: rotate(90deg);

                        &::after {
                            opacity: 0;
                        }
                    }
                }
            }

            &-wrap {
                display: flex;
                align-items: center;

                @media (min-width: 1800px) {
                    width: 44%;
                }

                @include media-breakpoint-down(md) {
                    justify-content: space-between;
                }
            }

            &-icon {
                @include media-breakpoint-up(md) {
                    height: 36px;
                    position: absolute;

                    svg {
                        transform: translateX(20px);
                        opacity: 0;
                        transition: opacity .3s;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        stroke-opacity: 1;
                        stroke: $danger;
                        fill-opacity: 0;
                        transition: .3s;

                        path {
                            transition: .3s;
                            fill-opacity: 0;
                            stroke-opacity: 1;
                        }
                    }
                }

                @media (min-width: 1800px) {
                    left: 0px;
                }

                @media (max-width: 1799px) and (min-width: 1441px) {
                    left: 0px;
                }

                @include media-breakpoint-down(xxl) {
                    left: 0;
                }

                @include media-breakpoint-up(xxl) {
                    width: 53px;
                }

                @include media-breakpoint-between(md, xxl) {
                    width: 35px;
                }

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &-circle {
                position: relative;
                flex-shrink: 0;
                flex-grow: 0;
                width: 32px;
                height: 32px;
                background-color: transparent;
                border: 1px solid #dedede;
                border-radius: 50%;
                transform: rotate(-90deg);
                transition: transform 1s;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 25%;
                    width: 14.67px;
                    height: 1px;
                    background-color: $primary;
                    opacity: 1;
                    transition: background-color 1s, opacity 1s
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 25%;
                    left: 50%;
                    width: 1px;
                    height: 14.67px;
                    background-color: $primary
                }

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        &-content {
            background-color: $primary;
            color: #fff;
            transform: scaleX(-1);

            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                right: 0;
                width: 49.4861%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                overflow: auto;
                overflow-x: hidden;
                left: 50.5%;
                transition: opacity .6s .4s, visibility .6s .4s, background .6s;
                scrollbar-width: thin;
                scrollbar-color: $danger transparent;

                &::-webkit-scrollbar {
                    width: 5px;

                    &-track {
                        background: $primary;
                    }

                    &-thumb {
                        background: $danger;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                font-size: 14px;
                transition: opacity .6s .4s, visibility .6s .4s, height .6s;
                height: 0px;
                overflow: hidden;
            }

            p {
                font-size: rems(20px);

                @media (max-width: 425px) {
                    font-size: 16px;
                }

                &+ul {
                    margin-top: 24px;
                }
            }

            &.active {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                z-index: 10;
                transition-delay: 0s;
            }

            &__wrap {
                transform: scaleX(-1);

                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
            }

            &__text {
                @include media-breakpoint-up(xxl) {
                    padding: 5.5vw 80px 5.5vw 103px;
                    width: 950px;
                    max-width: 100%;
                }
                @include media-breakpoint-between(xl, xxl) {
                    padding: 5.5vw 40px 40px 40px;
                }
                @include media-breakpoint-between(md, xl) {
                    padding: 5.5vw 24px 40px 20px;
                }

                @include media-breakpoint-down(md) {
                    padding: 20px 24px;
                }
            }

            &__img {
                width: 100%;
            }

        }

        &-buttons {
            display: flex;
            align-items: center;
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            flex-wrap: wrap;
            margin-top: 40px;
        }

        &--light & {
            p + p {
                margin-top: 24px;
            }
            @include media-breakpoint-up(md) {
                min-height: 780px;
            }
            &-link {
                @include media-breakpoint-up(md) {
                    color: #fff;
                }
            }
        }

        &:not(&--light) & {
            &-link {
                &.active {
                    @include media-breakpoint-up(md) {
                        font-weight: 600;
                    }
                }

                @include media-breakpoint-up(xl) {
                    font-size: 28px;
                    margin-bottom: 2.5vw;
                }
            }

            &-content {
                &__text {
                    @include media-breakpoint-up(xxl) {
                        padding: 2.95vw 80px 2.95vw 2.5vw;
                    }
                    @include media-breakpoint-between(xl, xxl) {
                        padding: 2.95vw 40px 2.95vw 2.5vw;
                    }
                    @include media-breakpoint-between(md, xl) {
                        padding: 2.95vw 24px 2.95vw 2.5vw;
                    }
                }
            }
        }
    }

    &--bordered {
        border-bottom: 3px solid $gray-100;
        padding-bottom: 28px;
    }

}