.carousel-section {
    &.background {

        @include media-breakpoint-up(xxl) {
            padding-block: 104px;
        }

        @include media-breakpoint-between(md, xxl) {
            padding-block: 86px;
        }

        @include media-breakpoint-between(sm, md) {
            padding-block: 64px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 48px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            &:hover {
                background-color: $gray-200;
                border-color: $gray-200;
            }

            &:active {
                background-color: $gray-300;
                border-color: $gray-300;
            }
        }
    }

    &.contrast {

        .swiper-button-prev,
        .swiper-button-next {
            color: $white;

            &:hover,
            &:active {
                background-color: $white;
                border-color: $white;
                color: $primary;
            }
        }
    }

    &__slider {
        @include media-breakpoint-up(xxl) {
            margin-top: 60px;
        }

        @include media-breakpoint-between(lg, xxl) {
            margin-top: 88px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 40px;
        }

        &.containered {
            @include media-breakpoint-between(sm, md) {
                padding-right: 20%;
            }
            @include media-breakpoint-down(sm) {
                padding-right: calc(100vw - 48px - 217px);
            }
        }
        &.container{
            @include media-breakpoint-up(xxl) {
                padding-left: 0;
                padding-right: 0;
                max-width: min(1600px, calc(100% - 160px));
            }
        
            @include media-breakpoint-between(xl, xxl) {
                padding-left: 0;
                padding-right: 0;
                width: calc(100% - 80px);
            }
            
            @include media-breakpoint-down(lg) {
                padding-left: 24px;
                padding-right: 50px;
            }
        }
    }

    &--news {
        @include media-breakpoint-up(xxl) {
            margin-top: 104px;
        }
    }
        
    &--news &__slider {
        @include media-breakpoint-between(md, xxl) {
            padding-right: 3%;
        }
        @include media-breakpoint-down(sm) {
            padding-right: calc(100vw - 48px - 252px);
        }
    }
    

    &__slider:not(.swiper-initialized) {
        padding-right: 24px;
    }

    &__slider:not(.swiper-initialized) & {
        &__wrapper {
            flex-wrap: wrap;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }

        &__slide {
            width: calc(50% - 5px);

            &:nth-child(n+5) {
                display: none;
            }

            .news-card {
                &__img {
                    height: 108px;

                    img.contain {
                        max-width: 70%;
                        max-height: 50%;
                    }
                }
            }
        }
    }

    &__slide {
        &--bordered {
            &:not(:last-child) {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 24px;
                    height: calc(100% - 48px);
                    width: 1px;
                    background-color: $gray-200;
                    transition: background-color .3s;
                }
            }

            .news-card {
                position: relative;
                padding-bottom: 24px;
                height: 100%;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: calc(100% + 32px);
                    height: 100%;
                    background-color: $gray-100;
                    z-index: -1;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .3s, visibility .3s;

                    @include media-breakpoint-down(lg) {
                        width: calc(100% + 27px);
                    }
                }
            }

            &:hover {
                &::after {
                    background-color: $gray-100;
                }

                .news-card {
                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .news-card {
            &__body {
                @include media-breakpoint-up(lg) {
                    width: 90%;
                }

                @include media-breakpoint-down(lg) {
                    padding-right: 24px;
                }

                @include media-breakpoint-down(sm) {
                    br {
                        display: none;
                    }
                }
            }
        }
    }

    &__btn {
        @include media-breakpoint-up(md) {
            margin-top: 45px;
        }

        @include media-breakpoint-between(sm, md) {
            margin-top: 40px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 32px;

            .btn {
                width: 100%;
            }
        }
    }

    &__title {
        @media (max-width: 330px) {
            br {
                display: none;
            }
        }
    }

    &--gallery {
        padding-bottom: 56px;
        .swiper-slide {
            img {
                width: 100%;
            }
        }

        &.bordered-section {
            @include media-breakpoint-up(lg) {
                padding-bottom: 104px;
            }

            @include media-breakpoint-between(md, lg) {
                padding-bottom: 64px;
            }

            @include media-breakpoint-down(md) {
                padding-bottom: 56px;
            }
        }

    }
}