.speaker-card {
    font-size: rems(16px);

    &__wrap {
        display: flex;
        grid-column-gap: 8.7%;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            grid-row-gap: 20px;
        }
    }

    &__title {
        margin-bottom: min(16px, 1vw);
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            font-size: rems(32px);
        }

        @include media-breakpoint-down(lg) {
            font-size: 30px;
        }
    }

    &__img {
        flex: 0 0 auto;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            width: 20%;
            height: fit-content;
            aspect-ratio: 1 / 1;
            position: relative;

            img {
                position: absolute;
                top: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: calc(100vw - 48px);
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }
    &__info {
        flex: 1;
    }

    &__text {

        @include media-breakpoint-up(lg) {
            width: 84%;
        }

        &:not(:last-child) {
            margin-bottom: max(2vw, 20px);
        }

        .lead {
            margin-bottom: min(28px, 1.5vw);
            font-weight: 600;
            color: $white;
            font-size: 16px;
            margin-top: max(2vw,20px);
        }

        .is-style {
            &>:last-child {
                margin-bottom: 0;
            }
            &>:first-child {
                margin-top: 0;
            }
        }

        ul:not(.compact, .dotted) {
            list-style: none;
            padding-left: 0;

            &>li {
                &+li {
                    margin-top: 0;
                }
                &:not(:last-child) {
                    padding-bottom: min(30px, 1.9vw);
                    border-bottom: 1px solid rgba(#fff, .12);
                }
                &:not(:first-child) {
                    padding-top: min(30px, 1.9vw);
                }

                ul {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(sm) {
                    &:not(:last-child) {
                        padding-bottom: 1.9vw;
                        border-bottom: 1px solid rgba(#fff, .12);
                    }
                    &:not(:first-child) {
                        padding-top: 1.9vw;
                    }
                }
            }
        }
        ul.compact {
            list-style: none;
            padding-left: 0;

            li+li {
                margin-top: 0;
            }
        }
    }
}