.page-navigation {
    width: 100%;
    display: none;
    transition: .4s ease;

    &__wrap {
        position: relative;
        transition: .4s ease;
        background-color: #fff;
    }

    &__wrap-scrollbar {
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
    }

    &__scrollbar {
        background-color: $gray-200;
        height: 1px;

        &-drag {
            background-color: $gray-300;
            height: 1px;
        }

    }

    &:not(&--static, &.fixed) &__scrollbar[style="display: none;"] {
        display: block !important;
        background-color: rgba($gray-300, .4)
    }

    &__item {
        width: auto;
    }

    &__link {
        line-height: 150%;
        letter-spacing: -0.02em;
        font-feature-settings: 'ss08' on;
        padding: 23px 3vw;
        cursor: pointer;
        display: block;
        color: $body-color;

        &:first-child {
            padding-left: 0;
        }

        &.active {
            pointer-events: none;
        }

        &--underlined.active {
            position: relative;

            &::after {
                content: '';
                height: 3px;
                width: calc(100% - 3vw);
                display: block;
                background-color: $danger;
                position: absolute;
                bottom: 0;
            }
        }

        &:not(&--underlined).active {
            color: $danger;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 18px;
        }

        @include media-breakpoint-down(xxl) {
            font-size: 14px;
        }
    }

    &.fixed {
        position: fixed;
        display: block;
        transition: transform .4s ease;
        z-index: 99;

        @include media-breakpoint-up(xxl) {
            top: 79px;
        }

        @include media-breakpoint-down(xxl) {
            top: 73px;
        }

        &.hide {
            transform: translateY(-210%);
        }
    }

    &.fixed & {
        &__link {
            &--underlined.active {
                &::after {
                    display: none;
                }
            }

            &.active {
                color: $danger;
            }
        }

    }

    &--static {
        border-bottom: 1px solid $gray-100;

        @include media-breakpoint-up(lg) {
            margin-top: 110px;
        }

        @include media-breakpoint-between(md, lg) {
            margin-top: 64px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 56px;
        }
    }

    &--static & {
        &__link {
            &.active {
                color: $body-color;
            }
        }
    }
}