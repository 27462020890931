.popup-section {
    color: $body-color;

    &__title {
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            margin-bottom: 104px;
        }

        @include media-breakpoint-between(md, lg) {
            margin-bottom: 64px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 56px;
        }
    }

    &__content {

        h3,
        .h3 {
            margin-bottom: max(16px, 2.25vw);
            margin-top: max(20px, 3vw);

            @include media-breakpoint-up(lg) {
                font-size: rems(32px);
            }
        }

        p+p {
            @include media-breakpoint-up(lg) {
                margin-top: 30px;
            }
        }

        p {
            @include media-breakpoint-down(md) {
                font-size: 12px;
            }

            @include media-breakpoint-down(sm) {
                br {
                    display: none;
                }
            }
        }

        img {
            max-width: 100%;
            margin-block: 25px;
        }

        .two_columns {
            margin-block: 30px;

            @include media-breakpoint-up(lg) {
                column-count: 2;
                break-inside: avoid;
            }
        }

        .swiper-slide img {
            margin: 0;
            width: 100%;
        }
    }
}