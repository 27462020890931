.carousel-projects-section{
    &__slider{
        @include media-breakpoint-up(xxl){
            margin-top: 56px;
            padding-right: 31vw;
        }
        @include media-breakpoint-between(lg, xxl){
            margin-top: 88px;
            padding-right: 31vw;
        }
        @include media-breakpoint-down(lg){
            margin-top: 40px;
            padding-right: 20vw;
        }
        @include media-breakpoint-down(md){
            padding-right: 18%;
        }
    }
    &__slide{
        &.swiper-slide-active{
            .project-card{
                &__img{
                    &:not(&--placeholder){
                        &::before{
                            opacity: 1;
                        } 
                    }
                    
                }
            }
        }
    }
    
}