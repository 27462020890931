@import 'speaker-card';

.speakers-section {
    background-color: $primary;

    @include media-breakpoint-up(lg) {
        padding-block: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        padding-block: 64px;
    }

    @include media-breakpoint-down(md) {
        padding-block: 56px;
    }

    &__description {
        margin-bottom: 56px;

        h3, .h3 {
            font-size: 20px;
        }
    }

    &__item {
        &:not(:last-child) {
            border-bottom: 1px solid rgba($gray-200, .2);
            padding-bottom: 56px;
        }

        &:not(:first-child) {
            padding-top: 56px;
        }

        &--big {
            .speaker-card {
                &__wrap {
                    grid-column-gap: 7.2%;
                }

                &__img {
                    @include media-breakpoint-up(sm) {
                        width: 27%;
                        height: 100%;
                        aspect-ratio: 31/37;
                    }
                }

                &__title {
                    margin-bottom: min(30px, 1.9vw);

                    @include media-breakpoint-up(lg) {
                        font-size: rems(46px);
                    }

                    @include media-breakpoint-between(sm, lg) {
                        font-size: 30px;
                    }
                }

                &__post {
                    font-size: rems(20px);
                }

                &__text {
                    
                    @include media-breakpoint-up(lg){
                        width: 78%;
                    }

                    &:not(:last-child) {
                        margin-bottom: max(3.5vw, 20px);
                    }

                    .lead {
                        font-size: rems(20px);
                        margin-top: max(3.5vw, 20px);

                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}