.modal{
    &-content{
        border: none;
        border-radius: 0;
        @include media-breakpoint-up(md){
            padding: 56px 72px 56px 40px;
        }
        @include media-breakpoint-down(md){
            padding: 40px 32px;
        }
    }
    &-close{
        position: absolute;
        background: no-repeat;
        line-height: 1;
        font-size: rems(40px);
        padding: 0;
        color: $primary;
        opacity: 1;
        @include media-breakpoint-up(md){
            right: 24px;
            top: 24px;
        }
        @include media-breakpoint-down(md){
            right: 20px;
            top: 20px;
        }
    }
    &-header{
        padding: 0;
        border: 0;
        @include media-breakpoint-up(md){
            margin-bottom: 32px;
        }
        @include media-breakpoint-down(md){
            margin-bottom: 16px;
        }
    }
    &-title{
        font-weight: 600;
        font-size: rems(32px);
        line-height: 120%;
        color: $primary;
    }
}