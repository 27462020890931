.feedback-card {
    padding: 38px 32px 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column-gap: 15px;
    position: relative;
    height: 100%;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #111;
        opacity: 0;
        transition: opacity .3s;
        z-index: 0;
    }

    &__text {
        z-index: 1;

        h3, .h3 {
            @include media-breakpoint-up(lg) {
                font-size: rems(24px);
            }

            @include media-breakpoint-between(sm, lg) {
                font-size: 26px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }
        }
    }

    &__icon {
        z-index: 1;
        object-fit: contain;

        @include media-breakpoint-up(sm) {
            height: 80px;
            width: 80px;
        }

        @include media-breakpoint-down(sm) {
            height: 65px;
            width: 65px;
        }
    }

    &__arrow {
        position: absolute;
        right: 24px;
        bottom: 24px;
        z-index: 1;

        @include media-breakpoint-up(md) {
            height: 56px;
            width: 56px;
        }

        @include media-breakpoint-down(md) {
            height: 40px;
            width: 40px;
        }

        svg {
            path {
                transition: stroke .3s;
            }
        }
    }

    &:hover {
        &::after {
            opacity: .03;
        }
    }
    &:hover &__arrow {
        svg {
            path {
                stroke: $danger !important;
            }
        }
    }
}