@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon/fonts/icomoon.eot?lmwl3q');
  src:  url('assets/fonts/icomoon/fonts/icomoon.eot?lmwl3q#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon/fonts/icomoon.ttf?lmwl3q') format('truetype'),
    url('assets/fonts/icomoon/fonts/icomoon.woff?lmwl3q') format('woff'),
    url('assets/fonts/icomoon/fonts/icomoon.svg?lmwl3q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

%icomoon{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"], [class*=" icon-"] {
  @extend %icomoon;
}

.icon-file:before {
  content: "\e908";
}
.icon-share:before {
  content: "\e90d";
}
.icon-close_simple:before {
  content: "\e903";
}
.icon-chevron:before {
  content: "\e902";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-attention:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-link:before {
  content: "\e906";
}
.icon-play:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e909";
}
.icon-slider_arrow:before {
  content: "\e90a";
}
.icon-tg:before {
  content: "\e90b";
}
.icon-vk:before {
  content: "\e90c";
}
