.tabs-section {
    &__description{
        margin-bottom: 56px;
    }

    &__nav{
        grid-column-gap: 5vw;
        border: none;
        flex-wrap: nowrap;
        overflow: auto;
        margin-inline: -24px;
        padding-inline: 24px;

        &::-webkit-scrollbar {
            height: 0;
        }

        @include media-breakpoint-down(md){
            padding-bottom: 15px;
        }

        &-link{
            border-width: 0 0 4px 0;
            border-color: $white;
            border-style: solid;
            border-radius: 0;
            padding: 0 4px;
            color: $gray-300;
            font-weight: 600;
            background-color: $white;
            transition: .3s;
            white-space: nowrap;

            &:hover{
                color: $primary;
            }

            &.active{
                color: $primary;
                border-color: $danger;
            }
        }
    }
    &__content{
        &-pane{
            @include media-breakpoint-up(sm){
                padding-top: 48px;
            }
            @include media-breakpoint-down(sm){
                padding-top: 20px;
            }

            h3, .h3{
                margin-block: 32px;
            }
        }
    }
}