.docs-section {
    &__description {
        margin-bottom: 50px;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        grid-column-gap: 15px;
        font-size: rems(20px);
        color: $body-color;
        padding-block: 17px;
        justify-content: space-between;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #BBBBBB;
        }

        &-info {
            color: $gray-600;
            font-size: rems(16px);
            display: flex;
            grid-column-gap: 20px;
            align-items: center;
        }

        &-size {
            white-space: nowrap;
        }

        &-icon {
            height: 22px;
            width: 22px;
            flex: 0 0 auto;
            line-height: 0;
        }
    }
}