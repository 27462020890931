.project-card {
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #fff;
    transition: .3s;
    height: 32.4479166667vw;
    max-height: 530px;
    min-height: fit-content;
    display: block;

    @include media-breakpoint-down(md) {
        height: 100%;
        max-height: none;
    }

    &:hover {
        color: #fff;
    }

    &:hover &__img img {
        transform: scale(1.05);
    }

    &__img {
        position: absolute;
        inset: 0;
        overflow: hidden;
        z-index: -1;
        transform-origin: left top;
        will-change: transform;
        background-color: $primary;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s;
            will-change: transform;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(154.77deg, rgba(0, 0, 0, 0.2) 16.36%, rgba(0, 0, 0, 0) 59.57%);
            opacity: 0;
            z-index: 1;
            transition: opacity .6s;
        }
    }

    &__body {
        @include media-breakpoint-up(lg) {
            padding: 56px;
        }

        @include media-breakpoint-down(lg) {
            padding: 30px;
        }

        @media (max-width: 425px) {
            padding: 20px 15px;
        }
    }

    &__title {
        color: #fff;
        font-weight: 600;
        line-height: 100%;
        transition: .3s;
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            line-height: 110%;

            br {
                display: none;
            }
        }

        @include media-breakpoint-up(xl) {
            font-size: 32px;
        }

        @include media-breakpoint-between(md, xl) {
            font-size: 24px;
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }

        @media (max-width: 425px) {
            font-size: 15px;
        }
    }

    &__text {
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            br {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            p {
                font-size: 12px;
            }
        }
    }
}