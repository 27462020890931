.form-search {
    position: relative;

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid $gray-200;
        border-radius: 32px;
        background-color: #fff;
        width: 100%;
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        overflow: hidden;
        width: 100%;

        &::after {
            content: '';
            height: 28px;
            width: 1px;
            background: $gray-200;
            top: 50%;
            margin-top: auto;
            margin-bottom: auto;
            z-index: 0;
        }
    }

    &__input, &__input.form-control {
        border: none !important;
        font-size: 16px;
        line-height: 150%;
        padding-inline: 24px;
        letter-spacing: -0.24px;
        width: 100%;
        background: none !important;
        border-radius: 25px;

        &::placeholder {
            color: $gray-600;
        }

        &::-webkit-search-cancel-button {
            display: none;
        }

        &:focus {
            box-shadow: none !important;
            background: none;
        }

        &:valid,
        &:invalid {
            background-image: none !important;
        }
    }

    &__btn {
        background: none;
        border: none;
        padding: 8px;
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        white-space: nowrap;

        &[type=reset] {
            font-size: 16px;
            color: #BCBCBC;
            position: absolute;
            right: -4px;
            transform: rotate(90deg);
            display: block;
            padding: 6px 11px;
            line-height: 0;
        }

        &--submit {
            color: $primary;
            font-size: 20px;
            padding-inline: 16px 20px;

            span {
                font-size: 16px;
                color: $body-color;
                letter-spacing: -0.02em;
            }
        }

        i {
            pointer-events: none;
        }

        &:hover {
            color: $gray-600;

            span {
                color: $gray-600;
            }
        }
    }

    &:not(&--expand).error &__row {
        border-color: $danger;
    }

    &:hover:not(&--expand) &__row {
        border-color: $primary;
    }

    &--expand {
        width: 26px;

    }

    &--expand & {
        &__wrap {
            position: absolute;
            width: 26px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: background-color 1s;
        }

        &__group {
            width: 0;
            opacity: 0;
            visibility: hidden;
        }

        &__row {
            border-color: #fff;
        }

        &__btn {
            span {
                display: none;
            }

            &--submit {
                padding: 15px;
                padding-inline: 2px;
            }
        }

        &__input {
            padding: 11px 23px 11px 16px;
            font-size: 12px;

            &::placeholder {
                color: $gray-200;
            }
        }

        .show &__group {
            opacity: 1;
            visibility: visible;
        }

        .show &__input {
            width: 100%;
        }

        .show &__btn--submit {
            font-size: 16px;
            padding-inline: 9px 18px;
            transition: .3s;
        }

        .show.error &__row {
            border-color: $danger;
        }
    }

    &--expand.show & {
        &__group {
            opacity: 1;
            visibility: visible;
        }

        &__input {
            width: 100%;
        }

        &__btn--submit {
            font-size: 20px;
            padding: 10px 16px 10px 8px;
        }
    }

    &--expand.show.error &__row {
        border-color: $danger;
    }

    &__suggest {
        transition: .3s;
        position: absolute;
        width: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            padding-inline: 24px;
        }
        @include media-breakpoint-down(sm) {
            padding-inline: 24px 10px;
        }

        &-wrap{
            overflow: auto;
            max-height: 400px;
            display: flex;
            flex-direction: column;
            grid-row-gap: 20px;
            font-size: 16px;

            &::-webkit-scrollbar {
                width: 4px;
              
                &-track {
                background: #fff;
                }
                
                &-thumb {
                    background: $gray-300;
                    border-radius: 10px;
                }
            }
        }

        &.active {
            padding-block: 20px 24px;
            z-index: 10;
            background-color: #fff;
            border: 1px solid $light-blue;
            border-top-width: 0;
            border-radius: 0 0 24px 24px;
            opacity: 1;

            &::before {
                content: '';
                height: 1px;
                width: calc(100% - 48px);
                background-color: $gray-200;
                display: block;
                position: absolute;
                left: 24px;
                transform: translateY(-20px);
            }
        }

        a {
            color: $gray-600;
            overflow-wrap: break-word;

            b {
                color: $body-color;
            }

            &:hover {
                color: $light-blue;

                b {
                    color: $light-blue;
                }
            }
        }
    }

    &--suggests & {
        &__wrap {
            border: 1px solid $light-blue;
            border-bottom-color: transparent;
            border-radius: 24px 24px 0 0;
        }

        &__row {
            border: none;
            background-color: transparent;
        }
    }

    .form-invalid-feedback{
        display: none !important;
    }
}

.page-search {
    .form-search {
        &:hover:not(.form-search--expand) .form-search__row {
            border-color: $light-blue;
        }
    }
}