// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff !default;
$gray-100: #F4F4F4;
$gray-200: #DEDEDE;
$gray-300: #BCBCBC;
$gray-400: #ced4da !default;
$gray-500: #c4c4c4 !default;
$gray-600: #909090;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #002F6C;
$light-blue:#0041A0;
$dark-blue: #001E46;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #FF0000;
$tone-red: #BF1D26;
$dark-red: #81080F;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #27AE60;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "light-blue": $light-blue,
    "red":        $danger
  ),
  $theme-colors
);

$enable-negative-margins: true;
$enable-dark-mode: false;
$grid-gutter-width: 24px;
$mySpacer: 15px;
$spacers: (
  0: 0,
  1: $mySpacer * .25,
  2: $mySpacer * .5,
  3: $mySpacer,
  4: $mySpacer * 1.5,
  5: $mySpacer * 3,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 767.99px,
  lg: 1025px,
  xl: 1201px,
  xxl: 1441px
);

$container-max-widths: (
  // sm: 540px,
  // md: 720px,
  // lg: 960px,
  // xl: 1357px,
  xxl: 1760px
);

$body-color: #111111;
$base-fontsize: 20px;

// кнопки
$btn-transition:  color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
$btn-border-width: 1px;

// формы
$form-floating-height:            48px;
$form-floating-line-height:       1.25;
$form-floating-padding-x:         20px;
$form-floating-padding-y:         19px;
$form-floating-input-padding-t:   13px;
$form-floating-input-padding-b:   19px;
$form-floating-label-opacity:     .65;
$form-floating-label-transform:   scale(.85) translateY(-1rem) translateX(.15rem);
$form-floating-transition:        opacity .3s ease-in-out, transform .3s ease-in-out;
$input-btn-focus-width:           2px;

//переменные для блока с результатами
$borderColor: null;

// аккордион
$accordion-padding-y:                     24px;
$accordion-padding-x:                     0;
// $accordion-color:                         $body-color; // Sass variable because of $accordion-button-icon
// $accordion-bg:                            $body-bg;
$accordion-border-width:                  0;
$accordion-border-radius:                 0;
$accordion-inner-border-radius:           0;

$accordion-body-padding-y:                24px;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;
// $accordion-button-color:                  $accordion-color;
// $accordion-button-bg:                     var(--#{$prefix}accordion-bg);
$accordion-transition:                    $btn-transition, border-radius .15s ease, padding .3s ease;
$accordion-button-active-bg:              'transpatent';
// $accordion-button-active-color:           shade-color($primary, 10%);

// $accordion-button-focus-border-color:     $input-focus-border-color;
$accordion-button-focus-box-shadow:       'none';

:root {
  --bs-border-width: 1px;
} 