.contact-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row-gap: 14px;
    transition: .3s;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        padding: 32px 25px 37px 32px;
    }

    @include media-breakpoint-down(md) {
        padding: 24px;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 10px;

        &-title {
            font-weight: 600;
            font-size: rems(28px);
            line-height: 100%;
            letter-spacing: -0.02em;
            color: $primary;
            transition: .3s;
        }

        &-icon {
            border-radius: 50%;
            background-color: $light-blue;
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            transition: .3s;
            border: 1px solid $light-blue;

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            &:hover{
                background-color: $primary;
                border-color: $primary;
            }
        }

    }

    &__content {
        font-size: rems(16px);

        p {
            &+p {
                margin-top: 8px;
            }

            &+ul {
                margin-top: 24px;
            }
        }

        ul {
            padding-left: 0;
            list-style: none;
        }

        a:not(:hover) {
            color: $light-blue;
        }
    }
}