@import './contact-card';
@import './feedback-card';

.contacts-section {

    &:not(&--with-title) {
        margin-top: 56px;
    }

    &__container {
        margin-top: 56px;
    }

    &__description {

        h2,
        .h2 {
            font-weight: 400;

            @include media-breakpoint-up(lg) {
                font-size: rems(32px);
            }

            @media (max-width: 375px) {
                font-size: 20px;
            }
        }
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-column-gap: 56px;
        grid-row-gap: 20px;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
            width: fit-content;
        }

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }

        &-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            font-size: 20px;
            line-height: 120%;
        }
    }

    &__map {
        position: relative;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            padding: 3.5vw;
            background: $gray-100;
            margin-block: 53px 56px;
        }

        @include media-breakpoint-down(md) {
            margin-inline: -24px;
        }

        &-list {
            @include media-breakpoint-up(md) {
                width: max(38%, 400px);
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        &-item {

            @include media-breakpoint-down(md) {
                &:not(:first-child) {
                    border-top: 1px solid $gray-200;
                }
            }

            .contact-card {
                z-index: 2;
                position: relative;
            }

            &__img {
                display: block;

                img {
                    height: 100%;
                    width: 100%;
                    transition: .3s;
                    object-fit: cover;
                }

                @include media-breakpoint-up(md) {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;

                }

                @include media-breakpoint-down(md) {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;
                    width: 100%;
                }
            }


            &.active {
                .contact-card {
                    background-color: $primary;
                    color: #fff;

                    &__head {
                        &-title {
                            color: #fff;
                        }

                        &-icon {
                            background-color: $primary;
                            border-color: #fff;

                            &:hover {
                                background-color: $light-blue;
                                border-color: #fff;
                            }
                        }
                    }

                    &__content {

                        a:not(:hover) {
                            color: #fff;
                        }
                    }
                }
            }

            &.active &__img {
                opacity: 1;
                visibility: visible;

                @include media-breakpoint-down(md) {
                    height: 350px;
                }
            }
        }
    }

    &__feedback {
        background-color: $gray-100;
        padding-block: 40px 32px;

        &-item {
            height: 285px;

            @include media-breakpoint-down(md) {
                height: unset;
                min-height: 250px;

                &:not(:first-child) {
                    margin-top: 24px;
                }
            }

            &:nth-child(3n - 2),
            &:nth-child(2n - 2) {
                .feedback-card {
                    color: #fff;

                    h3,
                    .h3,
                    h2,
                    .h2 {
                        color: #fff;
                    }

                    &__arrow {
                        svg {
                            path {
                                stroke: #fff;
                            }
                        }
                    }

                    &:hover::after {
                        opacity: .14;
                    }
                }
            }

            &:nth-child(3n - 2) {
                .feedback-card {
                    background-color: $light-blue;
                }
            }

            &:nth-child(2n - 2) {
                .feedback-card {
                    background-color: $primary;
                }
            }

            &:nth-child(3n - 3) {
                @include media-breakpoint-between(md, lg) {
                    margin-top: 24px;
                    width: 100%;
                }
            }
        }
    }
}