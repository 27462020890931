[data-icon-animation] svg path {
    transition: stroke .15s;
}

.background-animate {
    // overflow: hidden;
    position: relative;
    background-color: $primary;
    z-index: 1;
    clip-path: inset(0px);

    &__bg {
        position: fixed;
        top: 0;
        // width: 100%;
        height: 100%;
        min-height: 100vh;
        width: 100vw;
        pointer-events: none;
        will-change: transform;
        z-index: -1;
    }
}

.wow {
    visibility: visible !important;
    transition: .3s;

    &:not(.animated) {
        opacity: 0;
    }

    &.animated {
        opacity: 1;
    }

    &.fadeUp {
        transition: all .5s 0s ease-out;
        transform: translateY(50px);

        &.animated {
            transform: translateY(0);
        }
    }

    &.slideToLeft {
        &:not(.swiper-wrapper) {
            transform: translateX(85px);

            &.animated {
                transform: translateX(0);
                transition: transform .55s 0s ease-out;
            }

        }

        &.swiper-wrapper {
            .swiper-slide {
                transform: translateX(85px);
            }

            &.animated .swiper-slide {
                transform: translateX(0);
                transition: transform .55s 0s ease-out;
            }
        }
    }
}