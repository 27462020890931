.text-section {
    &.background {
        @include media-breakpoint-up(xxl) {
            padding-block: 100px 200px;
        }

        @include media-breakpoint-between(md, xxl) {
            padding-block: 70px 140px;
        }

        @include media-breakpoint-between(sm, md) {
            padding-block: 60px 100px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 48px;
        }
    }

    &__content {
        font-size: 16px;

        @include media-breakpoint-down(lx) {
            font-size: 14px;
        }

        &:not(&--right) {
            position: sticky;
            top: 90px;
        }

        h2,
        .h2 {
            font-feature-settings: 'ss08' on;
            font-weight: 400;
            margin-bottom: 24px;

            @include media-breakpoint-down(sm) {
                br {
                    display: none;
                }
            }

            @media (max-width: 375px) {
                font-size: 20px;
            }
        }

        h3,
        .h3 {

            margin-top: 40px;
            margin-bottom:16px;

            @include media-breakpoint-up(lg) {
                font-size: 32px;
            }

            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }

        h4,
        .h4 {
            margin-bottom: 24px;

            @include media-breakpoint-up(xxl) {
                font-size: 24px;
            }

            @include media-breakpoint-between(md, xxl) {
                font-size: 20px;
            }

            @include media-breakpoint-between(sm, md) {
                font-size: 18px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }


        img {
            margin-block: 32px 18px;
            max-width: 100%;
            max-height: 100%;
        }

        li+li {
            margin-top: 8px;
        }

        ul,
        ol {
            margin-top: 16px;
        }

        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            margin-top: 40px;
        }

        small {
            color: $gray-600;
            font-size: 12px;
        }
        p+h4,
        p+.h4,
        p+ul,
        p+ol {
            margin-top: 24px;
        }

        &--right {
            @include media-breakpoint-down(xl) {
                br {
                    display: none;
                }
            }
        }

        &--numeric-style {
            ol {
                padding-left: 0;

                li {
                    &::before {
                        position: relative;
                        margin-right: 10px;
                        color: $danger;
                        font-weight: 600;
                    }
                }
            }
        }

        hr {
            margin-block: 24px;
        }

        img + h2, img + .h2, img + h3, img + .h3, img + h4, img + .h4, img + h5, img + .h5, img + h6, img + .h6 {
            margin-top: 4px;
            margin-bottom: 24px;
        }

        .digits-list {
            li {
                display: flex;
                gap: 32px;

                @include media-breakpoint-down(md) {
                    gap: 24px;
                }
            }

            ol {
                margin-top: 0;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    margin-top: 34px;
                }
                @include media-breakpoint-down(sm) {
                    margin-top: 18px;
                }
            }

           
        }
        .bordered-list {
            padding-left: 0;
            li {
                border-bottom: 1px solid rgba($gray-100, .1);
                padding-bottom: 32px;
                margin-bottom: 24px;
                list-style: none;

                @include media-breakpoint-down(md) {
                    gap: 24px;
                }

                .h4, h4 {
                    margin-bottom: 16px;
                    margin-top: 0;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 7px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

    }

    &:not(.background) &__content {

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $primary;
        }

        p+h3,
        p+.h3 {
            margin-top: 48px;
        }

        .bordered-list {
            li {
                border-color: $gray-200;
            }
        }
    }

    &__btn {
        margin-top: 56px;
    }

    &__section-btn {
        margin-top: 40px;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
    }

    &__title {
        margin-bottom: 56px;

        b {
            color: $primary !important;
        }

        @include media-breakpoint-down(xl) {
            margin-bottom: 40px;
        }
    }

    &:not(&--normal, &--medium, &--big) {
        p {
            line-height: 120%;
        }

        @include media-breakpoint-up(xl) {

            font-size: 32px;

            p {
                font-size: 32px;
            }
        }

        @include media-breakpoint-between(sm, xl) {
            font-size: 20px;

            p {
                font-size: 20px;
            }
        }
    }


    &:not(&--no-accent) {

        h1,
        .h1,
        h2,
        .h2 {
            b {
                color: $danger;
            }
        }
    }

    &--border-bottom {
        border-bottom: 2px solid $gray-200;

        @include media-breakpoint-up(lg) {
            padding-bottom: 104px;
        }

        @include media-breakpoint-between(md, lg) {
            padding-bottom: 64px;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 56px;
        }
    }

    &--medium &__content {
        font-size: rems(20px);

        h3,
        .h3 {
            p {
                margin-bottom: 8px;
            }
        }
    }

    &--big &__content {
        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }

        @include media-breakpoint-between(md, xl) {
            font-size: 20px;
        }

        li {
            line-height: 150%;

            @include media-breakpoint-up(xl) {
                margin-bottom: 32px;
                font-size: 24px;
            }

            @include media-breakpoint-between(md, xl) {
                font-size: 20px;
            }
        }

        ul {
            @include media-breakpoint-up(xl) {
                padding-left: 20px;

                &>li {
                    &::marker {
                        font-size: 20px;
                    }
                }
            }

            @include media-breakpoint-between(md, xl) {
                padding-left: 18px;

                &>li {
                    &::marker {
                        font-size: 15px;
                    }
                }
            }
        }

        p {
            @include media-breakpoint-up(xl) {
                font-size: 24px;
            }

            @include media-breakpoint-between(md, xl) {
                font-size: 20px;
            }
        }
    }

    &--partnership {
        .left-col {
            @include media-breakpoint-up(xl) {
                padding-right: 9%;
            }
        }
    }
    &--partnership & {
        &__content {
            position: relative;
            top: unset;

            
        }
    }

    &._small-spacers {
        &.background {
            @include media-breakpoint-up(xxl) {
                padding-block: 88px;
            }

            @include media-breakpoint-between(md, xxl) {
                padding-block: 88px;
            }

            @include media-breakpoint-between(sm, md) {
                padding-block: 60px;
            }
        }
        &:not(.background) {
            @include media-breakpoint-up(lg) {
                margin-bottom: 88px;
            }
        }
    }
}