.sidebar-card {
    position: sticky;
    top: 80px;
    background: #F4F4F4;
    padding: 32px;

    h3 {
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
            font-size: rems(20px);
        }
        
        @include media-breakpoint-down(md) {
            font-size: rems(18px);
        }
    }

    p {
        font-size: 14px;
        color: $gray-600;
    }

    .btn {
        margin-top: 24px;
    }
}