.news-section {
    margin-top: 48px;

    &__item {
        .news-card {
            &__title {
                line-height: 150%;
                letter-spacing: -0.02em;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &:nth-child(1n + 4) {
                margin-top: 56px;
            }
        }

        @include media-breakpoint-between(sm, lg) {
            &:nth-child(1n + 3) {
                margin-top: 30px;
            }
        }

        @include media-breakpoint-down(sm) {
            &:not(:first-child) {
               margin-top: 30px; 
            } 
        }
    }
}

.empty-section{
    &__wrap{
        text-align: center;
        color: $secondary;
        padding-block: 25px;
        h2{
            color: $secondary;
        }
    }
}