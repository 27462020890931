.badges-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 26px;
    grid-row-gap: 16px;

    @include media-breakpoint-down(md) {
        grid-column-gap: 8px;
        grid-row-gap: 8px;
    }
}
.badge-item {
    padding: 4px 10px;
    background-color: $light-blue;
    border-radius: 20px;
    color: $white;
    font-size: 16px;
    line-height: 1.5;

    &.red {
        background-color: $danger;
    }
    &.white {
        background-color: $white;
        color: $blue;
    }
    &.text-gray {
        color: $gray-600;
    }

    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}