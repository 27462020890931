.brand-section{
    &__subtitle{
        font-weight: 600;
        margin-bottom: 19px;
    }
    &__block{
        margin-top: 49px;
    }
    &__list {
        display: flex;
        width: 100%;

        &--images{
            gap: 40px;
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(sm){
            &:not(&--images){
               flex-direction: column; 
            }
        }
        
    }
    &__item{
        
        width: 100%;

        &-color{
            height: 66px;
            width: 100%;
        }
        &-label{
            letter-spacing: -0.02em;
            color: $gray-600;
            width: 100%;
            text-align: center;
            margin-top: 16px;
        }

        img{
            max-width: 100%;
        }

        &--big &-color{
            @include media-breakpoint-up(sm){
                height: 110px;
            }
        }
        &--small &-color{
            @include media-breakpoint-up(sm){
                height: 56px;
            }
        }
    }

    &__list--images &__item{
        width: fit-content;
        &-label{
            margin-top: 8px;
            text-align: unset;
        }
    }
}