.feedback-section {
    margin-top: 0;
    @include media-breakpoint-up(lg) {
        padding-block: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        padding-block: 64px;
    }

    @include media-breakpoint-down(md) {
        padding-block: 56px;
    }
}