.events-section {
    &__title {
        margin-bottom: 64px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 48px;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 64px;

        &--big {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 30px;
        }
    }
    &__item {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 104px;
    }

    @include media-breakpoint-between(md, lg) {
        margin-top: 64px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 56px;
    }
}