@import "bootstrap/scss/tables";
table {
    @extend .table;

    font-size: 16px;
    line-height: 110%;

    thead {
        th {
            color: $gray-600;
            font-size: 14px;
            padding-bottom: 20px;
        }
    }

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        border-color: $gray-200;
    }

    &>:not(caption)>*>* {
        padding: 18px .5rem;
    }

    tr>* {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    td, th {
        &>*:last-child {
            margin-bottom: 0;
        }
    }

    tbody>tr:last-child {

        th,
        td {
            border-bottom: 0;
        }
    }

}
.table-responsive {
    th,
    td {
        @include media-breakpoint-down(md) {
            min-width: 180px;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;

        &-track {
            background: $white;
        }

        &-thumb {
            background: $gray-300;
            border-radius: 3px;
        }
    }

}
.table_wrap {
    @extend .table-responsive;
}