@import './form-fields';

.form{
    &-header{
        @include media-breakpoint-up(md){
            margin-bottom: 32px;
        }
        @include media-breakpoint-down(md){
            margin-bottom: 16px;
        }
        &__title{
            line-height: 120%;
            color: $primary;
            font-weight: 600;
            width: 94%;

            @include media-breakpoint-up(md){
                font-size: 32px;
            }
            @include media-breakpoint-down(md){
                font-size: 20px;
            }
        }
        &__preview {
            max-width: 400px;
            margin: 20px 0 0 0;
        }
    }

    &-captcha.smart-captcha{
        max-width: 100%;
        min-width: auto;
        @include media-breakpoint-up(lg){
            margin-top: 40px;
            width: 60%;
        }
        @include media-breakpoint-down(lg){
            margin-top: 24px;
        }
    }

    &-agree{
        font-size: 14px;
        @include media-breakpoint-up(lg){
            margin-top: 40px;
        }
        @include media-breakpoint-down(lg){
            margin-top: 24px;
        }
    }
    &-agree + &-agree {
        margin-top: 24px;
    }
    &-agree &-check{
        font-size: 14px;
    }
    &-buttons{
        @include media-breakpoint-up(lg){
            margin-top: 40px;
        }
        @include media-breakpoint-down(lg){
            margin-top: 32px;
            button{
                width: 100%;
            }
        }
    }
    &-responce{
        letter-spacing: -0.02em;
        line-height: 150%;

        @include media-breakpoint-up(sm){
            font-size: 16px;
        }
        @include media-breakpoint-down(sm){
            font-size: 14px;
        }

        h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            font-weight: 600;
            margin-bottom: 32px;
            color: $primary;
        }

        p{
            margin-bottom: 0;

            & + &{
                margin-top: 1rem;
            }
        }

        button:not(.is-close){
            @include media-breakpoint-up(sm){
                margin-top: 40px;
            }
            @include media-breakpoint-down(sm){
                margin-top: 20px;
            }
        }

        &.error{
            color: $danger;

            @include media-breakpoint-up(sm){
                margin-top: 20px;
            }
            @include media-breakpoint-down(sm){
                margin-top: 14px;
            }
        }

        &.success.fancybox__content {
            @include media-breakpoint-up(md) {
                max-width: 670px;
            }
        }
    }
    &-checks-row {
        display: flex;
        align-items: center;
        grid-column-gap: 20px;
        grid-row-gap: 5px;
        flex-wrap: wrap;

        .form-check + .form-check {
            margin-top: 0;
        }
    }
}
