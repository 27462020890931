@import './result-card';

.search-results {
    padding-top: 32px;
    margin-top: 0;

    &__head {
        margin-bottom: 24px;
    }

    &__title {
        @include media-breakpoint-up(sm) {
            font-size: rems(32px);
            margin-bottom: rems(32px);
        }

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            margin-bottom: 25px;
        }

        &-phrase {
            color: $danger;
            font-weight: 600;
        }
    }

    &__info {
        font-size: 16px;
        color: $gray-600;
    }

    &__pagination {
        .container {
            max-width: unset;
            padding: 0;
            margin: 0;
        }

        .main-pagination {
            margin-top: 40px;
        }
    }
}