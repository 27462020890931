.form-section {
    &.background {
        background-color: $gray-100;
        padding-block: 60px 56px
    }
    &--small-pt.background {
        padding-top: 11px;
    }

    &__wrap {
        &--white {
            background-color: #fff;    

            @include media-breakpoint-up(sm){
                padding: 3.9vw 6.4vw 3.9vw 3.9vw;
            }
            @include media-breakpoint-down(sm){
                padding: 8vw 3.9vw;
            }

            .form-agree {
                color: rgba($body-color, 0.5);

                a {
                    color: rgba($body-color, 0.5);

                    &:hover {
                        color: $danger;
                    }
                }
            }
        }
    }

    &__description {
        margin-bottom: 30px;

        h2, .h2 {
            @include media-breakpoint-up(lg) {
                font-size: rems(32px);
            }
        }
    }

    &__form {
        .form-floating {
            margin-top: 16px;
        }
    }

}