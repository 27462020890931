.event-card {
    display: block;

    &__img {
        position: relative;

        img {
            width: 100%;
            aspect-ratio: 16/9.3;
            object-fit: cover;
        }
    }
    &__badges {
        position: absolute;
        top: 16px;
        left: 16px;

        .badge-item {
            font-size: 14px;
        }
    }

    &__body {
        margin-top: 24px;

        @include media-breakpoint-up(sm) {
            .learn-info__item:not(:last-child) {
                border-color: $gray-200;
            }
        }
    }
    &__title {
        font-weight: 600;
        margin-top: 16px;
        font-size: 20px;
        margin-bottom: 0;
        transition: .3s;

        @include media-breakpoint-down(sm) {
            margin-top: 8px;
            font-size: 18px;
        }
    }

    .learn-info {
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            grid-column-gap: 8px;

            &__item {
                &:not(:last-child) {
                    border-right: 1px solid $gray-300;
                    padding-right: 8px;
                }
            }
        }
    }

    &:hover &__title {
        color: $danger;
    }
    &:active &__title {
        color: $tone-red;
    }
}