@import './services-card';

.services-section {
    color: $primary;

    &.background {
        @include media-breakpoint-up(xxl) {
            padding-block: 104px;
        }

        @include media-breakpoint-between(md, xxl) {
            padding-block: 86px;
        }

        @include media-breakpoint-between(sm, md) {
            padding-block: 64px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 48px;
        }
    }

    &__content {
        &:not(.no-accent) {

            h2 b,
            .h2 b {
                color: $danger;
            }
        }

        &:not(&--normal) {
            p {
                letter-spacing: -0.02em;
                line-height: 120%;
    
                
    
                @include media-breakpoint-up(xxl) {
                    font-size: 32px;
                }
    
                @include media-breakpoint-between(xl, xxl) {
                    font-size: 24px;
                }
    
                @include media-breakpoint-between(md, xl) {
                    font-size: 18px;
                    line-height: 150%;
                }
    
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
        &--normal {
            color: $body-color;
        }

        @include media-breakpoint-down(md) {

            h2,
            .h2,
            p {
                br {
                    display: none;
                }
            }
        }

        &:not(&--medium-md) {
            
            @include media-breakpoint-up(xxl) {
                margin-bottom: 88px;
            }

            @include media-breakpoint-between(lg, xxl) {
                margin-bottom: 80px;
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 56px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }
    }

    &__slider.swiper-initialized {
        width: 100%;
        padding: 0 20% 0 24px;
        max-width: unset;

        .services-card__list {
            padding-bottom: 85px;

            @include media-breakpoint-down(sm) {
                padding-bottom: 65px;
            }
        }
    }
    &__slider.swiper-initialized & {
        &__slide {
            &:not(&--md) { 

                &:nth-child(3n+1) .services-card {
                    background-color: $gray-100;
        
                    &__title, a {
                        color: $primary;
        
                        &:hover {
                            color: $danger;
                        }
                        &:active {
                            color: $tone-red;
                        }
                    }
            
                    &__description {
                        color: $body-color;
                    }
                    svg path {
                        stroke: $primary;
                    }
                    &:hover {
                        &::after {
                            opacity: .03;
                        }
                    }
                }
                &:nth-child(3n+2) .services-card {
                    background-color: $light-blue;
                }
                &:nth-child(3n+2), &:nth-child(3n+3) {
                    .services-card:not(.services-card--full-bg):hover {
                        .services-card__list-item {
                            a {
                                opacity: .3;
        
                                &:hover {
                                    color: $white;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            &--md {
                &:nth-child(3n + 1) .services-card {
                    background-color: $gray-100;
        
                    &__title, a {
                        color: $primary;
        
                        &:hover {
                            color: $danger;
                        }
                    }
            
                    &__description {
                        color: $body-color;
                    }
                    svg path {
                        stroke: $primary;
                    }
                    &:hover {
                        &::after {
                            opacity: .03;
                        }
                    }
                }
                &:nth-child(3n + 2) .services-card {
                    background-color: $light-blue;
                    color: $white;
            
                    a {
                        color: $white;
                    }
                }
                &:nth-child(3n + 3) .services-card {
                    background-color: $primary;
                    color: $white;
            
                    a {
                        color: $white;
                    }
            
                    svg path {
                        stroke: $white;
                    }
                }
            }
        }
    }
    &__slider:not(.swiper-initialized) & {
        &__wrapper {
            flex-wrap: wrap;
            grid-row-gap: 16px;
            grid-column-gap: 16px;
        }

        &__slide {
            height: unset;

            &:not(&--md) {
                width: calc(50% - 8px);

                &:nth-child(4n-3), &:nth-child(4n) {
                    .services-card {
                        background-color: $gray-100;
            
                        &__title, a {
                            color: $primary;
            
                            &:hover {
                                color: $danger;
                            }
                            &:active {
                                color: $tone-red;
                            }
                        }
                
                        &__description {
                            color: $body-color;
                        }
                        svg path {
                            stroke: $primary;
                        }
                        &:not(.services-card--full-bg):hover {
                            &::after {
                                opacity: .03;
                            }
                        }
                    }
                }
                &:nth-child(3), &:nth-child(4n+7) {
                    .services-card {
                        background-color: $light-blue;
                    }
                }
            }

            &--md {
                width: calc(33% - 8px);

                &:nth-child(2n + 1) {
                    .services-card {
                        background-color: $gray-100;
        
                        &__title, a {
                            color: $primary;
        
                            &:hover {
                                color: $danger;
                            }
                        }
                
                        &__description {
                            color: $body-color;
                        }
                        svg path {
                            stroke: $primary;
                        }
                        &:hover {
                            &::after {
                                opacity: .03;
                            }
                        }
                    }
                }
                
                &:nth-child(4n + 2) {
                    .services-card {
                        background-color: $light-blue;
                        color: $white;
                
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &.background &__slider.swiper-initialized &__slide--md {
        &:nth-child(3n + 1) .services-card {
            background-color: $white;

        }
    }

    &.background &__slider:not(.swiper-initialized) &{
        &__slide--md {
            &:nth-child(2n + 1) {
                .services-card {
                    background-color: $white;

                }
            }  
        }
        &__slide:not(&__slide--md) {   

            &:nth-child(4n-3), &:nth-child(4n) {
                .services-card {
                    background-color: $white;
                }
            }
        }
        
    }
}