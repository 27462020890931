.main-pagination {
    display: flex;
    align-items: center;
    grid-column-gap: 14px;
    margin-top: 85px;

    @include media-breakpoint-down(sm) {
        justify-content: center;
    }

    &__btn {
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $gray-200;
        transition: .3s;
        background-color: transparent;
        flex: 0 0 auto;

        @include media-breakpoint-up(sm){
            height: 40px;
            width: 40px;
        }
        @include media-breakpoint-down(sm){
            height: 30px;
            width: 30px;
        }

        i {
            pointer-events: none;
        }

        &:hover {
            background-color: $gray-100;
            border-color: $gray-100;
            color: $light-blue;
        }

        &:active {
            background-color: $gray-200;
            border-color: $gray-200;
            color: $light-blue;
        }

        &.disabled {
            pointer-events: none;
            color: $gray-600;
            border-color: $gray-300;
        }
    }

    &__list {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;

    }

    &__link {
        letter-spacing: -0.02em;
        font-feature-settings: 'ss08' on;
        color: $body-color;

        span {
            pointer-events: none;
        }

        @include media-breakpoint-up(sm) {
            padding: 5px 14px;
            font-size: 18px;
        }

        @include media-breakpoint-down(sm) {
            padding: 5px 9px;
            font-size: 15px;
        }
    }

    &__item.active &__link {
        position: relative;
        pointer-events: none;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            height: 4px;
            background-color: $danger;

            @include media-breakpoint-up(sm) {
                width: calc(100% - 28px);
                left: 14px;
            }

            @include media-breakpoint-down(sm) {
                width: calc(100% - 18px);
                left: 9px;
            }
        }
    }
}