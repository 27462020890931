.learn-detail {

    &__head {
        margin-top: 10px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 104px;
        }
    
        @include media-breakpoint-between(md, lg) {
            margin-bottom: 64px;
        }
    
        @include media-breakpoint-down(md) {
            margin-bottom: 56px;
        }
    }

    &__info {

        &--background {
            background-color: $gray-100;
            padding-block: 30px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 104px;
            }
        
            @include media-breakpoint-between(md, lg) {
                margin-bottom: 64px;
            }
        
            @include media-breakpoint-down(md) {
                margin-bottom: 56px;
            }
            @include media-breakpoint-down(sm) {
                padding-top: 0;
            }
        }
        &:not(&--background) {
            margin-bottom: 60px;

            @include media-breakpoint-up(md) {
                padding-block: 44px 56px;
            }
    
            @include media-breakpoint-down(md) {
                padding-block: 40px;
            }
            @include media-breakpoint-up(sm) {
                border-bottom: 3px solid $gray-100;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            grid-column-gap: 15px;
            grid-row-gap: 30px;
        }

        &-wrap {
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            overflow: hidden;
            @include media-breakpoint-down(md){
                flex-wrap: wrap;
                align-items: unset;
            }
        }

        &-title {
            font-size: 1em;
            line-height: 150%;
            color: $gray-600;
            margin-bottom: 8px;
        }

        &-value {
            font-weight: 600;
            font-size: 1.5em;
            line-height: 120%;
        }

        &-item{
            position: relative;
            padding-block: 12px;
            font-size: rems(16px);

            @media (max-width: 1200px) and (min-width: 768px) {
                &:not(:last-child){
                    padding-right: 2.5vw;
                }
                &:not(:first-child){
                    padding-left: 2.5vw;
                }
            }

            @include media-breakpoint-up(sm){
                &:not(:last-child){
                    padding-right: 4vw;
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 1px;
                        height: 100%;
                        background-color: $gray-200;
                    }
                }
                &:not(:first-child){
                    padding-left: 4vw;
                }
            }

            @include media-breakpoint-between(md, lg) {
                font-size: 12px;
            }
            @include media-breakpoint-between(sm, md){
                width: 33%;
                margin-bottom: 10px;
                margin-top: 10px;
                &:first-child{
                    &::before{
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        background-color: $gray-200;
                        height: 1px;
                        width: calc(98vw - 48px);
                    }
                }
                &:nth-child(3n+4) {
                    padding-left: 0;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                        background-color: $gray-200;
                        height: 1px;
                        width: calc(98vw - 48px);
                    }
                }
                &:nth-child(3n+3){
                    padding-right: 0;
                    &::after{
                        display: none;
                    }
                }
                &:last-child, &:nth-last-child(-n+2), &:nth-last-child(-n+3) {
                    &::before {
                        display: none;
                    }
                }
                
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                border-bottom: 1px solid $gray-200;
            }
        }

        &-container &-item{
            @include media-breakpoint-up(sm){
                &:not(:last-child){
                    padding-right: 3.5vw;
                }
                &:not(:first-child){
                    padding-left: 3.5vw;
                }
            }
        }
    }

    &__content {
        img {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                margin-block: 24px 56px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }

        li+li {
            margin-top: rems(16px);
        }

        p {
            margin-bottom: 32px;
        }

        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            margin-top: 56px;
        }

        ul,
        ol {
            margin-bottom: 32px;
            font-size: rems(16px);
        }


        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            margin-bottom: 8px;
        }

        h2, .h2 {
            margin-bottom: 56px;

            @include media-breakpoint-up(lg) {
                margin-top: 104px;
            }

            @include media-breakpoint-between(md, lg) {
                margin-top: 64px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 56px;
                margin-bottom: 40px;
            }
        }

        h3, .h3 {

            @include media-breakpoint-up(md) {
                margin-top: 56px;
                margin-bottom: 40px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 40px;
                margin-bottom: 30px;
            }
        }
    }

    &--event & {
        &__info {
            &:not(&--background) {
                @include media-breakpoint-up(md) {
                    margin-bottom: 104px;
                    padding-block: 56px;
                }
            }
            &-wrap {
                justify-content: space-between;
            }
            &-item {
                @include media-breakpoint-up(sm) {
                    flex: 1;

                    &:not(:first-child){
                        display: flex;
                        justify-content: center;
                    }
                    &:last-child {
                        justify-content: flex-end;
                        padding-right: 4vw;
                    }
                }
            }
        }
    }
}