.mar-0   { margin-bottom: 0 !important; }
.mar-10  { margin-bottom: 10px !important; }
.mar-20  { margin-bottom: 20px !important; }
.mar-30  { margin-bottom: 30px !important; }
.mar-40  { margin-bottom: 40px !important; }
.mar-60  { margin-bottom: 60px !important; }
.mar-80  { margin-bottom: 80px !important; }
.mar-100 { margin-bottom: 100px !important; }
.mar-120 { margin-bottom: 120px !important; }

.d-hidden {
	display: none !important;
}

.is-hr {
	position: relative;
	display: block;
	&.hr-10  { height: 10px; }
	&.hr-20  { height: 20px; }
	&.hr-30  { height: 30px; }
	&.hr-40  { height: 40px; }
	&.hr-50  { height: 50px; }
	&.hr-60  { height: 60px; }
	&.hr-70  { height: 70px; }
	&.hr-80  { height: 80px; }
	&.hr-90  { height: 90px; }
	&.hr-100 { height: 100px; }
	&.hr-120 { height: 120px; }
}

:root {
	--wrapperPadding: 80px;
}

.is-wrapper {
	box-sizing: border-box;
	position: relative;
	display: block;
	max-width: 1920px;
	margin: 0 auto;
	padding-left: var(--wrapperPadding);
	padding-right: var(--wrapperPadding);
}

.is-full-img {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.is-flexervice-lead {
	position: relative;
	display: block;
	overflow: hidden;
	color: #fff;
	background: #002f6c;

	.fs-back-image {
		opacity: 0;
		transform: scale(1.2);
	}
	.fs-wrapper {
		height: 100%;
		padding-top: 56px;
		padding-bottom: 120px;

		opacity: 0;
	}
	.fs-lead-crumbs {

	}
	.fs-lead-preview {

	}

	// mods
	.is-flexervice-style {
		h1 {
			max-width: 1100px;
			margin: 0 0 32px 0;
			color: #fff;
		}
		p {
			max-width: 740px;
			margin: 0 0 32px 0;
			font-size: 32px;
			line-height: 42px;
		}
	}


	.page-loaded & {
		.fs-back-image {
			opacity: 1;
			transform: none;
			transition: opacity 1500ms, transform 1500ms;
		}
		.fs-wrapper {
			opacity: 1;
			transform: none;
			transition: opacity 1500ms 300ms;
		}
	}
}

.is-flexervice-style {
	p {
		margin: 0 0 20px 0;
		&.strong-1 {
			margin-bottom: 24px;
			font-size: 32px;
			line-height: 38px;
			color: #002F6C;
		}
		&.strong-2 {
			margin-bottom: 32px;
			font-size: 20px;
			line-height: 30px;
		}
		&.strong-3 {
			margin-bottom: 70px;
			font-size: 32px;
			line-height: 38px;
		}
		&.strong-days {
			margin-bottom: 0;
			font-size: 90px;
			line-height: 90px;
			color: #002F6C;
		}
		&.lite-1 {
			margin-top: -10px;
			font-size: 12px;
			line-height: 18px;
			color: #909090;
		}
		&.lite-2 {
			margin: 0 0 20px 0;
			font-size: 16px;
			line-height: 24px;
			color: #909090;
		}
	}
	img {
		margin: 0 0 20px 0;
		display: block;
		max-width: 100%;
		height: auto !important;
	}
}

.is-flexervice-about {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 40px 0;
	.fs-a-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;

		box-sizing: border-box;
		position: relative;
		margin: 0 0 80px 0;
	}
	.fs-a-layout-header {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;
		padding: 0 100px 0 0;

		// mods
		h2 {
			font-weight: 600;
		}
	}
	.fs-a-layout-preview {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;
	}

	.fs-a-tiles {
		box-sizing: border-box;
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px 80px;
		overflow: hidden;
		margin: 0 0 80px 0;
		padding: 64px 0 0 0;
		&:after {
			pointer-events: none;
			user-select: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: 50%;
			width: 100vw;
			height: 1px;
			background: #DEDEDE;
			transform: translateX(-50%);
		}
	}
	.fs-a-tile-item {
		box-sizing: border-box;
		position: relative;
		display: block;
		min-width: 0;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: -40px;
			width: 1px;
			height: 80%;
			background: #DEDEDE;
		}
	}
	.fs-ati-icon {
		position: relative;
		display: block;
		margin: 0 0 24px 0;
		img {
			display: block;
		}
	}
	.fs-ati-name {
		position: relative;
		display: block;
		margin: 0 0 16px 0;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		color: #002F6C;
		letter-spacing: -0.02em;
		text-wrap: balance;
	}
	.fs-ati-preview {

	}
}

.is-flexervice-when-need {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 138px 0 120px 0;
	color: #fff;
	background: #032b60;
	.fs-wn-back {
		position: absolute;
		display: block;
		overflow: hidden;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 100%;
		margin-left: calc(100vw / -2);
		background-color: #032b60;
		clip-path: inset(0);
	}
	.fs-wn-front {
		position: relative;
		display: block;
		z-index: 10;
	}
	h2 {
		margin: 0 0 64px 0;
		color: #fff;
		b {
			color: #ff0000;
		}
	}
	.fs-wn-tiles {
		position: relative;
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(12, 1fr);
	}
	.fs-wn-tile {
		grid-column: span 4;

		box-sizing: border-box;
		position: relative;
		display: block;
		min-width: 0;
		height: 260px;
		background: #fff;
		--padding: 24px;

		&:nth-child(1),&:nth-child(2), &:nth-child(3), &:nth-child(4){
			grid-column: span 3;
		}
	}
	.fs-wn-tile-index {
		position: absolute;
		display: block;
		top: var(--padding);
		left: var(--padding);
	}
	.fs-wn-tile-name {
		position: absolute;
		display: block;
		bottom: var(--padding);
		left: var(--padding);
		right: var(--padding);

		font-size: 20px;
		line-height: 30px;
		color: #111;
		text-wrap: balance;
		letter-spacing: -0.02em;
	}
}

.is-flexervice-advantages {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 100px 0;
	&:after {
		pointer-events: none;
		user-select: none;
		position: absolute;
		display: block;
		content: '';
		bottom: 0;
		left: 50%;
		width: 100vw;
		height: 1px;
		background: #DEDEDE;
		transform: translateX(-50%);
	}
	.fs-a-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;

		box-sizing: border-box;
		position: relative;
	}
	.fs-a-layout-header {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;
		padding: 0 100px 0 0;

		// mods
		h2 {
			b {
				color: #FF0000;
			}
		}
	}
	.fs-a-layout-preview {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;

		// mods
		ul, ol {
			li {
				margin: 0 0 16px 0;
			}
		}
	}
}

.is-flexervice-localization {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 100px 0;
	.fs-l-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;

		box-sizing: border-box;
		position: relative;
	}
	.fs-l-layout-left {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;
		padding: 0 100px 0 0;
	}
	.fs-l-layout-right {
		flex-shrink: 0;
		flex-grow: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;

		// mods
		ul, ol {
			li {
				margin: 0 0 16px 0;
			}
		}
	}

	// mods
	h2 {
		margin: 0 0 52px 0;
		b {
			color: #FF0000;
		}
	}
}

.is-flexervice-functionality {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 90px 0;
	background: #F4F4F4;
	.fs-f-back {
		position: absolute;
		display: block;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 100%;
		margin-left: calc(100vw / -2);
		background: #F4F4F4;
	}
	.fs-f-front {
		position: relative;
		display: block;
	}
	.fs-f-rows {
		position: relative;
		display: block;
	}
	.fs-f-row-item {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;

		position: relative;
		margin: 0 0 32px 0;
		background: #fff;
		&:nth-child(even){
			flex-direction: row-reverse;
			.fs-fri-preview {
				color: #fff;
				background: #0041a0;
				border: none;
			}
			.fs-fri-p-name {
				color: #fff;
			}
		}
	}
	.fs-fri-preview {
		flex-grow: 0;
		flex-shrink: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 50%;
		padding: 50px 50px 30px 50px;
		border-right: 1px solid #f4f4f4;


		// mods
		p {
			max-width: 60%;
		}
		ul {
			li {
				margin-bottom: 16px;
			}
		}
	}
	.fs-fri-images {
		flex-grow: 0;
		flex-shrink: 0;

		position: relative;
		display: block;
		width: 50%;
		min-height: 600px;
	}
	.fs-fri-p-icon {
		position: relative;
		display: block;
		margin: 0 0 60px 0;
		img {
			display: block;
		}
	}
	.fs-fri-p-name {
		margin: 0 0 32px 0;
		font-size: 46px;
		line-height: 46px;
		color: #002F6C;
		letter-spacing: -0.02rem;
		font-weight: 600;
	}

	// mods
	h2 {
		margin: 0 0 88px 0;
		b {
			color: #f00;
		}
	}
}

.is-flexervice-slider-a {
	box-sizing: border-box;
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 0 96px 0;
	.swiper {
		height: 100%;
	}
	.swiper-wrapper {
		height: 100%;
	}
	.swiper-slide {
		.is-full-img {
			background-position: 0 0;
		}
	}
	.fs-slider-nav {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		box-sizing: border-box;
		position: absolute;
		z-index: 10;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 96px;
		padding: 0 24px;
		background: #fff;
	}
	.fs-slider-nav-count {
		display: block;
		font-size: 16px;
		line-height: 16px;
		color: #909090;
	}
	.fs-slider-nav-arrows {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;

		position: relative;
		width: 106px;
		height: 48px;
	}
}

.is-arrow {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 48px;
	height: 48px;
	border: 1px solid #DEDEDE;
	border-radius: 50%;
	transition: background 200ms, border 200ms;
	&:before {
		pointer-events: none;
		position: absolute;
		display: block;
		content: '';
		top: 50%;
		left: 50%;
		width: 19px;
		height: 26px;
		margin: -13px 0 0 -10px;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: 19px 26px;
	}
	&:hover {
		border-color: #f1f1f1;
		background: #f1f1f1;
	}
	&:active {
		border-color: #dedede;
		background: #DEDEDE;
		transition: none;
	}
	&.arrow-prev {
		&:before {
			background-image: url('/frontend/images/image14.svg');
		}
	}
	&.arrow-next {
		&:before {
			margin-left: -8px;
			background-image: url('/frontend/images/image15.svg');
		}
	}
}

.is-flexervice-form {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 80px 0;
	background: #F4F4F4;
	.fs-f-back {
		position: absolute;
		display: block;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 100%;
		margin-left: calc(100vw / -2);
		background: #F4F4F4;
	}
	.fs-f-front {
		position: relative;
		display: block;
		z-index: 10;
	}
	.fs-f-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;

		box-sizing: border-box;
		position: relative;
		padding: 40px;
		background: #fff;
	}
	.fs-f-layout-preview {
		flex-grow: 0;
		flex-shrink: 0;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 40%;
		padding: 0 50px 0 0;
	}
	.fs-f-layout-fields {
		flex-grow: 0;
		flex-shrink: 0;

		position: relative;
		display: block;
		width: 60%;
		margin: -20px 0 0 0;
	}
	.fs-fp-header {
		margin: 0 0 16px 0;
		font-size: 32px;
		line-height: 38px;
		font-weight: 600;
		letter-spacing: -.02em;
		color: #002F6C;
	}
	.fs-fp-header-sub {
		margin: 0 0 32px 0;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -.02em;
		color: #909090;
	}
	.fs-fp-contact-item {
		margin: 0 0 24px 0;
	}
	.fs-fp-ci-label {
		margin: 0 0 8px 0;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -.02em;
		color: #909090;
	}
	.fs-fp-ci-link {
		margin: 0 0 16px 0;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		letter-spacing: -.02em;
		color: #002F6C;
		a {
			color: #002F6C;
			&:hover {
				color: #f00;
			}
		}
	}
}

.is-columns {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: stretch;
	justify-content: space-between;
	.col {
		flex-grow: 1;
		width: 100%;
	}
	.col + .col {
		margin-left: 40px;
	}
	&.gap-0 {
		.col + .col { margin-left: 0; }
	}
	&.gap-10 {
		.col + .col { margin-left: 10px; }
	}
	&.gap-15 {
		.col + .col { margin-left: 15px; }
	}
	&.gap-30 {
		.col + .col { margin-left: 30px; }
	}
	&.gap-60 {
		.col + .col { margin-left: 60px; }
	}
	&.gap-80 {
		.col + .col { margin-left: 80px; }
	}
	&.gap-120 {
		.col + .col { margin-left: 120px; }
	}
	.col-demo {
		height: 50px;
		margin: 0 0 40px 0;
		background: #fcb82d;
	}
}

.is-flexervice-modules {
	position: relative;
	display: block;
	padding: 120px 0;
	.fs-s-header {
		position: relative;
		display: block;
		margin: 0 0 80px 0;
		h2 {
			b {
				color: #f00;
			}
		}
		p {
			font-size: 20px;
			line-height: 30px;
		}
	}
	.fs-m-tiles {
		box-sizing: border-box;
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 100px 160px;
		overflow: hidden;
	}
	.fs-m-tile-item {
		position: relative;
		display: block;
		min-width: 0;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: -80px;
			width: 1px;
			height: 100%;
			background: #DEDEDE;
		}
		&:after {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			bottom: -50px;
			left: -100px;
			right: -100px;
			height: 1px;
			background: #DEDEDE;
		}
		.is-flexervice-style {
			ul {
				li {
					margin-bottom: 16px;
				}
			}
		}
	}
	.fs-mti-icon {
		position: relative;
		display: block;
		margin: 0 0 32px 0;
		img {
			display: block;
			width: auto;
			height: 80px;
		}
	}
	.fs-mti-name {
		max-width: 480px;
		margin: 0 0 16px 0;
		font-size: 32px;
		line-height: 38px;
		font-weight: 600;
		letter-spacing: -.02em;
		color: #002F6C;
		text-wrap: balance;
	}
}

.is-flexervice-tasks {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 100px 0;
	color: #fff;
	background: #032b60;
	.fs-t-back {
		position: absolute;
		display: block;
		overflow: hidden;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 100%;
		margin-left: calc(100vw / -2);
		background-color: #032b60;
		clip-path: inset(0);
	}
	.fs-t-front {
		position: relative;
		display: block;
		z-index: 10;
	}
	h2 {
		margin-bottom: 88px;
		color: #fff;
		b {
			color: #f00;
		}
	}
	.fs-t-tiles {
		box-sizing: border-box;
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 40px 64px;
		overflow: hidden;
	}
	.fs-t-tile-item {
		position: relative;
		display: block;
		min-width: 0;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: -32px;
			width: 1px;
			height: 100%;
			background: rgba(255,255,255,.3);
		}
	}
	.fs-tti-name {
		margin: 0 0 32px 0;
		font-size: 32px;
		line-height: 38px;
		font-weight: 600;
		letter-spacing: -.02em;
	}
	.is-flexervice-style {
		ul {
			li {
				margin-bottom: 16px;
			}
		}
	}
}

.is-flexervice-effects {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 100px 0;
	&:after {
		pointer-events: none;
		user-select: none;
		position: absolute;
		display: block;
		content: '';
		bottom: 0;
		left: 50%;
		width: 100vw;
		height: 1px;
		background: #DEDEDE;
		transform: translateX(-50%);
	}
	.fs-e-header {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;

		box-sizing: border-box;
		position: relative;
		margin: 0 0 88px 0;
	}
	.fs-e-header-left {
		flex-grow: 0;
		flex-shrink: 0;

		position: relative;
		display: block;
		margin: 0 120px 0 0;
		h2 {
			margin: 0;
			b {
				color: #f00;
			}
		}
	}
	.fs-e-header-right {
		flex-grow: 1;
		flex-shrink: 1;

		position: relative;
		display: block;
		max-width: 860px;
	}
	.fs-e-tiles {
		box-sizing: border-box;
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 120px 120px;
		overflow: hidden;
		padding: 0 0 120px 0;
		&:after {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			bottom: -60px;
			left: -100px;
			right: -100px;
			height: 1px;
			background: #DEDEDE;
		}
	}
	.fs-e-tile-item {
		position: relative;
		display: block;
		min-width: 0;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: -60px;
			width: 1px;
			height: 100%;
			background: #DEDEDE;
		}
		&:after {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			bottom: -60px;
			left: -100px;
			right: -100px;
			height: 1px;
			background: #DEDEDE;
		}
	}
	.fs-eti-name {
		margin: 0 0 32px 0;
		font-size: 90px;
		line-height: 90px;
		letter-spacing: -.04em;
		color: #002F6C;
		white-space: nowrap;
	}
	.fs-eti-preview {
		text-wrap: balance;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -.04em;
		color: #002F6C;
	}
	.fs-e-footer {
		position: relative;
		display: block;
		max-width: 900px;
	}
}

.is-flexervice-stages {
	box-sizing: border-box;
	position: relative;
	display: block;
	padding: 120px 0 100px 0;
	.fs-s-header {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;

		box-sizing: border-box;
		position: relative;
		margin: 0 0 10px 0;
	}
	.fs-s-header-left {
		flex-shrink: 0;
		flex-grow: 0;

		position: relative;
		display: block;
		width: 550px;

		// mods
		h2 {
			margin: 0 0 32px 0;
			font-weight: 600;
		}
	}
	.fs-s-header-right {
		flex-shrink: 0;
		flex-grow: 0;

		position: relative;
		display: block;
	}

	.fs-s-tiles {
		display: flex;
		flex-direction: row-reverse;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: stretch;

		box-sizing: border-box;
		position: relative;
		padding: 0 0 50px 0;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			bottom: 50px;
			left: 0;
			width: 100%;
			height: 1px;
			background: #DEDEDE;
		}
	}
	.fs-s-tile-item {
		flex-grow: 1;
		flex-shrink: 1;

		box-sizing: border-box;
		position: relative;
		display: block;
		width: 100%;
		min-width: 0;
		&:last-child {
			.fs-sti-inner {
				border-left: none;
				&:after {
					display: none;
				}
			}
		}
		@for $i from 1 through 10 {
			&:nth-child(#{$i + 1}){
				padding-top: #{$i * 50}px;
			}
		}
	}
	.fs-sti-inner {
		box-sizing: border-box;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		padding: 32px 16px 1px 16px;
		border-left: 1px solid #DEDEDE;
		&:before {
			pointer-events: none;
			position: absolute;
			display: block;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 16px;
			background: #002F6C;
		}
		&:after {
			pointer-events: none;
			box-sizing: border-box;
			position: absolute;
			display: block;
			content: '';
			z-index: 10;
			top: 100%;
			left: 0;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background: #fff;
			border: 1px solid #DEDEDE;
			border-radius: 50%;
		}
	}
	.fs-sti-name {
		margin: 0 0 16px 0;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		letter-spacing: -.02em;
		color: #002F6C;
		text-wrap: balance;
	}
	.fs-sti-month {
		position: absolute;
		display: block;
		top: 100%;
		left: 0;
		margin: 10px 0 0 0;
		font-size: 16px;
		line-height: 16px;
		color: #000;
	}

	// mods
	.is-flexervice-style {
		ul {
			li {
				margin-bottom: 8px;
			}
		}
	}
}

// responsive
@media screen and (max-width: 1440px) {

	:root {
		--wrapperPadding: 40px;
	}

	.is-flexervice-style {

		p {
			&.strong-1, &.strong-3 {
				font-size: 26px;
				line-height: 34px;
			}
			&.strong-days {
				font-size: 70px;
				line-height: 70px;
			}
		}

	}

	.is-flexervice-about {
		padding: 80px 0 10px 0;
	}

	.is-flexervice-when-need {
		padding: 90px 0 100px 0;
		.fs-wn-tile {
			--padding: 16px;
		}
		.fs-wn-tile-name {
			font-size: 18px;
			line-height: 24px;
		}
	}

	.is-flexervice-advantages {
		padding: 80px 0 60px 0;
	}

	.is-flexervice-localization {
		padding: 80px 0 60px 0;
	}

	.is-flexervice-functionality {
		padding: 90px 0 70px 0;
		.fs-fri-images {
			min-height: 500px;
		}
		.fs-fri-p-name {
			font-size: 32px;
			line-height: 32px;
		}
	}

	.is-flexervice-modules {
		padding: 80px 0;
		.fs-mti-name {
			font-size: 26px;
			line-height: 32px;
		}
	}

	.is-flexervice-tasks {
		padding: 90px 0 100px 0;
		.fs-tti-name {
			font-size: 26px;
			line-height: 32px;
		}
	}

	.is-flexervice-effects {
		padding: 80px 0 60px 0;
		.fs-eti-name {
			margin-bottom: 20px;
			font-size: 64px;
			line-height: 64px;
		}
	}

	.is-flexervice-stages {
		padding: 80px 0 60px 0;
		.fs-sti-name {
			font-size: 18px;
			line-height: 26px;
		}
	}

}

// tablet
@media screen and (max-width: 1024px) {

	:root {
		--wrapperPadding: 20px;
	}

	.t-hidden {
		display: none !important;
	}

	.is-columns {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: flex-start;
		.col {
			flex-grow: 0;
			flex-shrink: 0;
			width: 100%;
			margin: 0 !important;
		}
	}

	.is-flexervice-style {
		p {
			&.strong-3 {
				margin-bottom: 30px;
			}
			&.strong-days {
				margin: 0 0 32px 0;
				font-size: 56px;
				line-height: 56px;
			}
		}
		img {
			&.image-1 {
				margin-bottom: 40px;
			}
		}
	}

	.is-flexervice-about {
		.fs-a-layout-header {
			width: auto;
		}
		.fs-a-layout-preview {
			flex-shrink: 1;
			flex-grow: 1;
			width: 100%;
		}
	}

	.is-flexervice-advantages {
		.fs-a-layout {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.fs-a-layout-header {
			width: 100%;
			margin: 0 0 50px 0;
			padding: 0;
		}
		.fs-a-layout-preview {
			width: 100%;
		}
	}

	.is-flexervice-localization {

		.fs-l-layout {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.fs-l-layout-left {
			flex-shrink: 0;
			flex-grow: 0;
			width: 100%;
			padding: 0;
		}
		.fs-l-layout-right {
			width: 100%;
		}
	}

	.is-flexervice-functionality {
		h2 {
			margin-bottom: 50px;
		}
		.fs-f-row-item {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			&:nth-child(even){
				flex-direction: column;
			}
		}
		.fs-fri-preview {
			order: 10;
			width: 100%;
			padding: 30px 30px 10px 30px;
			border: none;
		}
		.fs-fri-images {
			order: 20;
			width: 100%;
			min-height: 0;
			padding-bottom: 85%;
		}
	}

	.is-flexervice-modules {
		.fs-m-tiles {
			gap: 80px 80px;
		}
		.fs-m-tile-item {
			&:before {
				left: -40px;
			}
			&:after {
				bottom: -40px;
			}
		}
	}

	.is-flexervice-effects {
		.fs-e-header {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;

			margin: 0 0 50px 0;
		}
		.fs-e-header-left {
			width: 100%;

			// mods
			h2 {
				margin-bottom: 40px;
			}
		}
		.fs-e-header-right {
			flex-shrink: 0;
			flex-grow: 0;

			width: 100%;
			min-width: 0;
		}
		.fs-e-tiles {
			gap: 80px 80px;
		}
		.fs-e-tile-item {
			&:before {
				left: -40px;
			}
			&:after {
				bottom: -40px;
			}
		}
		.fs-eti-name {
			margin-bottom: 20px;
			font-size: 54px;
			line-height: 54px;
		}
	}

	.is-flexervice-stages {
		.fs-s-header {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.fs-s-header-left {
			width: 100%;
			margin: 0 0 30px 0;
		}
		.fs-s-header-right {
			width: 100%;
		}
		.fs-s-tiles {
			display: flex;
			flex-direction: column-reverse;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 0;
			&:before {
				display: none;
			}
		}
		.fs-s-tile-item {
			flex-grow: 0;
			flex-shrink: 0;
			display: block;
			width: 100%;
			min-width: 0;
			margin: 0;
			padding: 0 !important;
			&:last-child {
				.fs-sti-inner {
					padding-top: 23px;
					border-left: 1px solid #DEDEDE;
					&:after {
						display: block;
						top: 0;
					}
					.fs-sti-month {
						top: -7px;
					}
				}
			}
			&:first-child {
				.fs-sti-inner {
					padding-bottom: 0;
				}
			}
		}
		.fs-sti-inner {
			height: auto;
			padding: 30px 0 32px 24px;
			border-left: 1px solid #DEDEDE;
			&:before {
				display: none;
			}
			&:after {
				top: 7px;
			}
		}
		.fs-sti-name {
			font-size: 22px;
			line-height: 30px;
		}
		.fs-sti-month {
			top: 0;
			left: 25px;
			margin: 0;
			font-size: 14px;
			line-height: 14px;
		}
	}

	.is-flexervice-form {
		.fs-fp-header {
			margin-bottom: 10px;
		}
		.fs-fp-ci-label {
			margin-bottom: 0;
		}
		.fs-f-layout {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 24px;
		}
		.fs-f-layout-preview {
			width: 100%;
			padding: 0 0 30px 0;
		}
		.fs-f-layout-fields {
			width: 100%;
			margin: 0;
		}
	}

}

// mobile
@media screen and (max-width: 845px) {

	.a-hidden {
		display: none !important;
	}

	.d-hidden {
		display: block !important;
	}

	.is-flexervice-style {
		p {
			&.strong-1 {
				font-size: 20px;
				line-height: 24px;
			}
			&.strong-2 {
				margin-bottom: 32px;
				font-size: 14px;
				line-height: 20px;
			}
			&.strong-3 {
				margin-bottom: 30px;
				font-size: 20px;
				line-height: 24px;
			}
			&.lite-2 {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.is-flexervice-lead {
		min-height: calc(100vh - 70px);
		.fs-wrapper {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.fs-lead-crumbs {
			display: none;
		}

		// mods
		.is-flexervice-style {
			h1 {
				margin: 0 0 24px 0;
				font-size: 36px;
				line-height: 36px;
				text-width: balance;
			}
			p {
				margin: 0 0 24px 0;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.btn {
			width: 100%;
			padding: 10px 14px;
		}
	}

	.is-flexervice-about {
		padding: 56px 0 10px 0;
		.fs-a-layout {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: flex-start;

			margin-bottom: 40px;
		}
		.fs-a-layout-header {
			width: 100%;
			padding: 0;
			h2 {
				margin: 0 0 32px 0;
			}
		}
		.fs-a-layout-preview {
			width: 100%;
			padding: 0;
			.btn {
				width: 100%;
				padding: 9px 14px;
			}
		}

		.fs-a-tiles {
			grid-template-columns: 1fr;
			gap: 32px 0;
			margin: 0 0 40px 0;
			padding: 0;
			&:after {
				display: none;
			}
		}
		.fs-a-tile-item {
			padding: 0 0 0 64px;
			&:before {
				top: 100%;
				left: 0;
				width: 100%;
				height: 1px;
				margin: 4px 0 0 0;
			}
		}
		.fs-ati-icon {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			margin: 0;
			img {
				width: 40px;
				height: auto !important;
			}
		}
		.fs-ati-name {
			margin: 0 0 16px 0;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.is-flexervice-when-need {
		padding: 56px 0 70px 0;
		h2 {
			margin-bottom: 40px;
		}
		.fs-wn-tiles {
			gap: 8px 0;
			grid-template-columns: 1fr;
		}
		.fs-wn-tile {
			grid-column: auto !important;
			height: auto;
			padding: 24px 24px 24px 86px;
			--padding: 24px;
		}
		.fs-wn-tile-index {
			width: 40px;
			height: auto !important;
		}
		.fs-wn-tile-name {
			position: relative;
			display: block;
			bottom: auto;
			left: auto;
			right: auto;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.is-flexervice-advantages {
		padding: 56px 0 36px 0;
		.fs-a-layout-header {
			margin-bottom: 40px;
			h2 {
				margin-bottom: 0;
			}
		}
	}

	.is-flexervice-localization {
		padding: 56px 0 36px 0;
		h2 {
			margin-bottom: 32px;
		}
	}

	.is-flexervice-functionality {
		padding: 56px 0 36px 0;
		h2 {
			margin-bottom: 40px;
		}
		.fs-fri-preview {
			padding: 24px 24px 10px 24px;
			p {
				max-width: none;
			}
		}
		.fs-fri-p-icon {
			margin: 0 0 16px 0;
			img {
				width: auto !important;
				height: 48px;
			}
		}
		.fs-fri-p-name {
			margin: 0 0 20px 0;
			font-size: 20px;
			line-height: 24px;
		}
	}

	.is-flexervice-slider-a {
		padding-bottom: 80px;
		.fs-slider-nav {
			height: 80px;
		}
	}

	.is-flexervice-modules {
		padding: 56px 0 36px 0;
		.fs-s-header {
			margin: 0 0 40px 0;
			h2 {
				margin-bottom: 32px;
			}
			p {
				margin-bottom: 40px;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.fs-m-tiles {
			grid-template-columns: 1fr;
			gap: 32px 0;
		}
		.fs-m-tile-item {
			padding: 0 0 0 64px;
			&:before {
				display: none;
			}
			&:after {
				bottom: -8px;
			}
			.is-flexervice-style {
				ul {
					li {
						margin-bottom: 8px;
					}
				}
			}
		}
		.fs-mti-icon {
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
			img {
				width: 40px;
				height: auto !important;
			}
		}
		.fs-mti-name {
			margin-bottom: 16px;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.is-flexervice-tasks {
		padding: 56px 0 46px 0;
		h2 {
			margin-bottom: 40px;
		}
		.fs-t-tiles {
			gap: 36px 0;
			grid-template-columns: 1fr;
		}
		.fs-t-tile-item {
			&:before {
				top: 100%;
				left: 0;
				width: 100%;
				height: 1px;
				margin: 11px 0 0 0;
			}
		}
		.fs-tti-name {
			margin-bottom: 24px;
			font-size: 20px;
			line-height: 30px;
		}
	}

	.is-flexervice-effects {
		padding: 56px 0 36px 0;
		.fs-e-header {
			margin-bottom: 40px;
		}
		.fs-e-header-left {
			h2 {
				margin-bottom: 32px;
			}
		}
		.fs-e-tiles {
			grid-template-columns: 1fr;
			gap: 40px 0;
			padding: 0 0 52px 0;
		}
		.fs-e-tile-item {
			&:before {
				display: none;
			}
			&:after {
				bottom: -25px;
			}
		}
		.fs-eti-name {
			margin-bottom: 8px;
		}
		.fs-eti-preview {
			max-width: 90%;
			font-size: 14px;
			line-height: 20px;
		}
		.fs-e-footer {
			.btn {
				width: 100%;
				padding: 9px 14px;
			}
		}
	}

	.is-flexervice-stages {
		padding: 56px 0 40px 0;
		.fs-sti-name {
			margin: 0 0 16px 0;
			font-size: 16px;
			line-height: 24px;
		}
		.btn {
			width: 100%;
			padding: 14px 14px;
		}
	}

	.is-flexervice-form {
		padding: 56px 0 56px 0;
		.fs-fp-header {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 24px;
		}
		.fs-fp-header-sub {
			margin-bottom: 32px;
			font-size: 14px;
			line-height: 20px;
		}
		.fs-fp-ci-label {
			margin-bottom: 8px;
			font-size: 14px;
			line-height: 20px;
		}
		.fs-fp-ci-link {
			font-size: 16px;
			line-height: 24px;
		}
		.btn {
			width: 100%;
			padding: 9px 14px;
		}

		// mods
		.form-floating >.form-control:not(:placeholder-shown) {
			padding-bottom: 16px;
		}
	}

}
