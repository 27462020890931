@import '../../assets/fonts/icomoon/style';

@font-face {
  font-family: 'GrtskPeta';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/grtsk-peta/GrtskPeta-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'GrtskPeta';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/grtsk-peta/GrtskPeta-Italic.woff2) format('woff2');
}
@font-face {
  font-family: 'GrtskPeta';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(assets/fonts/grtsk-peta/GrtskPeta-Semibold.woff2) format('woff2');
}
@font-face {
  font-family: 'GrtskPeta';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(assets/fonts/grtsk-peta/GrtskPeta-SemiboldItalic.woff2) format('woff2');
}
@font-face {
  font-family: 'SsDigits';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(assets/fonts/ssdigits.woff2) format('woff2');
}

body {
  font-family: 'GrtskPeta', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: rems(20px);
  line-height: 150%;
  letter-spacing: -0.02em;
  font-feature-settings: 'ss08' on;
}

h1, .h1 {
  line-height: 100%;
  color: $primary;
  letter-spacing: -0.02em;
  font-weight: 400;

  @include media-breakpoint-up(xxl){
    font-size: 90px;
  }
  @include media-breakpoint-between(xl, xxl){
    font-size: 72px;
  }
  @include media-breakpoint-between(lg, xl){
    font-size: 67px;
  }
  @include media-breakpoint-between(md, lg){
    font-size: 65px;
  }
  @include media-breakpoint-between(sm, md){
    font-size: 50px;
  }
  @include media-breakpoint-down(sm){
    font-size: 36px;
  }
  @media (max-width: 375px) {
    font-size: 30px;
  }
}

h2, .h2 {
  line-height: 100%;
  color: $primary;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(xl){
    font-size: 46px;
  }
  @include media-breakpoint-between(lg, xl){
    font-size: 40px;
  }
  @include media-breakpoint-down(lg){
    font-size: 32px;
  }
  @media (max-width: 375px) {
    font-size: 25px;
  }
}

h3, .h3 {
  line-height: 120%;
  color: $primary;
  letter-spacing: -0.02em;
  @include media-breakpoint-up(lg){
    font-size: 32px;
  }
  @include media-breakpoint-between(md, lg){
    font-size: 25px;
  }
  @include media-breakpoint-down(md){
    font-size: 22px;
  }
}

h4, .h4 {
  color: $primary;
  line-height: 150%;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    font-size: 27px;
  }
  @include media-breakpoint-between(md, lg){
    font-size: 22px;
  }
  @include media-breakpoint-down(md){
    font-size: 20px;
  }
}

h5, .h5 {
  color: $primary;
  line-height: 150%;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

h6, .h6 {
  color: $primary;
  line-height: 150%;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}


p{
  margin-bottom: 0;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;

  & + &{
    margin-top: 1em;
  }
  @include media-breakpoint-down(xl){
    font-size: 14px;
  }
}

.lead {
  color: $primary;
  line-height: 120%;
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }

  &20 {
    line-height: 150%;
    letter-spacing: -0.4px;
    & + & {
      margin-top: 1em;
    }
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
}