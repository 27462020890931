.info-section {
    &._small-spacer {
        @include media-breakpoint-up(lg) {
            margin-top: 88px;
        }
    }
    &__wrap {
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: auto 100%;
        background-image: url('/frontend/images/info-section-bg.png');
        background-color: $gray-100;
        padding: 40px 36px;
        position: relative;
        line-height: 0;
        box-sizing: border-box;
        min-height: 250px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media-breakpoint-down(lg) {
            padding: 30px;
        }
        @include media-breakpoint-down(md) {
            background-position: 200px 100%;
        }
        @include media-breakpoint-down(sm) {
            background-image: none;
        }
    }

    .btn {
        margin-top: 30px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &__content {
        width: 72%;
        line-height: 1.5;

        &._with-image {
            width: 46%;
        }

        h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            margin-bottom: 24px;
            margin-top: 0;
        }

        & > *, & > div > * {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(lg) {
            p {
                br {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(md) {
            width: 100%;

            p, h2, h3 {
                br {
                    display: none;
                }
            }
        }
    }
}