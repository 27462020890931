.specials-leadblock {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__picture {
        position: absolute;
        inset: 0;
        z-index: -1;
        height: 100%;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__content {
        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-down(md){
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(sm) {
            padding-top: 55px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 40px;
        }
    }
    
    &__metrics {
        border-top: 1px solid rgba($white, .3);
        padding-top: 67px;
        margin-top: 64px;

        @include media-breakpoint-down(md) {
            padding-top: 40px;
            margin-top: 40px;
        }

        &-wrap {
            display: flex;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        &-col {
            flex: 0 0 auto;
            width: 50%;
            --col-padding: 103px;

            &:first-child {
                border-right: 1px solid rgba($white, .3);
                padding-right: var(--col-padding);
            }
            &:last-child {
                padding-left: var(--col-padding);

                .specials-leadblock__metric-head {
                    @include media-breakpoint-up(xxl) {
                        width: 221px;
                    }
                    @include media-breakpoint-between(lg, xxl) {
                        width: 15.4vw;
                    }
                    @include media-breakpoint-between(md, lg) {
                        width: 12vw;
                    }
                }
            }
            @include media-breakpoint-down(xxl) {
                --col-padding: 6vw;
            }
            @include media-breakpoint-down(xl) {
                --col-padding: 4vw;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                --col-padding: 0;

                &:first-child {
                    border-right: none;
                    margin-bottom: 30px;
                }
            }
        }
    }
    &__metric {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        line-height: 1.5;
        font-size: 16px;
        gap: 35px;

        &:last-child {
            margin-bottom: 0;
        }

        &-head {
            font-size: 65px;
            white-space: nowrap;
            width: 196px;
            flex: 0 0 auto;
            line-height: 1;

            sup {
                font-size: max(.31em, 10px);
                top: -1.7em;
            }
        }
        @include media-breakpoint-down(xxl) {
            margin-bottom: 2.2vw;
            gap: 2.2vw;
            &-head {
                font-size: 4.5vw;
                width: 13.5vw;
            }

           
        }
        @media (max-width: 1310px) and (min-width: 1025px) {
            br {
                display: none;
            }
        }
        @include media-breakpoint-down(lg) {
            font-size: max(1.4vw, 12px);
            &-head {
                font-size: 3.5vw;
                width: 10.5vw;
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
            font-size: 14px;

            &-head {
                font-size: min(50px, 8vw);
                width: 27vw;
            }
        }
        @media (max-width: 425px) {
            br {
                display: none;
            }
            flex-direction: column;
            gap: 7px;
            align-items: unset;

            &-head {
                font-size: 30px;
                width: fit-content;
            }
        }
    }

    &__nav {
        .page-navigation {
            position: relative;
            display: block;

            @include media-breakpoint-down(lg){
                display: none;
            }

            &__wrap {
                background-color: transparent;
            }

            &__link {
                color: $primary;
                &:hover {
                    color: $danger;
                }
            }

            &__scrollbar {
                background-color: rgba($white, .3);
                &-drag {
                    background-color: transparent;
                }
            }
        }
    }
}