.features-section{
    &__list{
        @include media-breakpoint-up(xxl){
            margin-top: 88px;
        }
        @include media-breakpoint-up(xl){
            padding-left: 13px;
            padding-right: 13px;
        }
        @include media-breakpoint-between(xl, xxl){
            margin-top: 70px;
        }
        @include media-breakpoint-between(md, xl){
            margin-top: 56px;
        }
        @include media-breakpoint-down(xl){
            margin-inline: 0;
        }
        @include media-breakpoint-down(md){
            margin-top: 40px;
        }

        &--margin-md {
            @include media-breakpoint-up(xxl){
                margin-top: 60px;
            }
            @include media-breakpoint-between(xl, xxl){
                margin-top: 60px;
            }
        }
    }
    &__item {
        &--no-border{
            border: none !important;

            &::after {
                display: none !important;
            }
        }
    }
    @include media-breakpoint-up(xl){

        &__item.col-xl-4{
            padding-bottom: 40px;
            padding-left: 32px;
            padding-right: 32px;
            border-right: 1px solid $gray-200;
            position: relative;

            &.features-section__item--no-border {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
        &__list > &__item.col-xl-4{
            
            &:nth-child(n+4){
                padding-top: 40px;
            }
            &:nth-child(3n+3){
                border-right: none;
                &.features-section__item--no-border {
                    padding-right: 12px;
                }
            }
            &:nth-child(3n+1){
                padding-left: 0;
                &::after{
                    content: '';
                    height: 1px;
                    width: 300%;
                    background-color: $gray-200;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }
            &:nth-last-child(-n+3), &:nth-last-child(-n+2), &:last-child{
                &::after{
                    display: none;
                }
            }
        }

        &__item:not(.col-xl-4){
            padding-bottom: 40px;
            position: relative;
        }
        &__list > &__item:not(.col-xl-4){
            padding-right: 26px;

            &:nth-child(n+3){
                padding-top: 40px;
            }
            &:nth-child(2n+1){
                border-right: 1px solid $gray-200;
                padding-left: 0;
                &::after{
                    content: '';
                    height: 1px;
                    width: 200%;
                    background-color: $gray-200;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }
            &:nth-child(2n+2){
                padding-left: 26px;
                padding-right: 0;
            }
            &:nth-last-child(-n+2), &:last-child{
                &::after{
                    display: none;
                }
            }            
        }
    }
    @include media-breakpoint-between(sm, xl){
        &__item{
            padding-bottom: 24px;
            padding-right: 24px;
            position: relative;
        }
        &__list > &__item{

            &:nth-child(n+3){
                padding-top: 24px;
            }
            &:nth-child(2n+1){
                border-right: 1px solid $gray-200;
                padding-left: 0;
                &::after{
                    content: '';
                    height: 1px;
                    width: 200%;
                    background-color: $gray-200;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }
            &:nth-child(2n+2){
                padding-left: 24px;
                padding-right: 0;
            }
            &:nth-last-child(-n+2), &:last-child{
                &::after{
                    display: none;
                }
            }            
        }
    }
    @include media-breakpoint-between(sm, md){
        &__item.col-md-6 {
            padding: 24px 0;
            border-bottom: 1px solid $gray-200;
            
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
                border-bottom: none;
            }  
            &:not(:last-child) {
                padding-bottom: 24px !important;
            } 
        }
        &__list > &__item.col-md-6 {
            &::after{
                display: none;
            }
            &:nth-child(2n+1){
                border-right: 0;
                padding-left: 0;
            }
            &:nth-child(2n+2){
                padding-left: 0;
            }  
        }
    }
    @include media-breakpoint-down(sm){
        &__item{
            padding: 24px 0;
            border-bottom: 1px solid $gray-200;
            
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
    &__card{
        display: flex;
        grid-row-gap: 24px;
        @include media-breakpoint-up(xxl){
            grid-column-gap: 24px;
        }
        @include media-breakpoint-down(xl){
            grid-column-gap: 18px;
        }
        @include media-breakpoint-down(lg){
            grid-column-gap: 16px;
        }
        @include media-breakpoint-up(md){
            flex-direction: column;
        }
        &-img{
            object-fit: contain;
            @include media-breakpoint-up(xxl){
                width: 80px;
                height: 80px;
            }
            @include media-breakpoint-down(xxl){
                width: 64px;
                height: 64px;
            }
            @include media-breakpoint-down(xl){
                width: 56px;
                height: 56px;
            }
            @include media-breakpoint-down(md){
                width: 40px;
                height: 40px;
            }
        }
        &-title{
            font-weight: 600;
            line-height: 150%;
            color: $primary;
            margin-bottom: 16px;

            b{
                color: $danger;
            }

            @include media-breakpoint-up(xl){
                font-size: rems(24px);
                width: 85%;
            }
            @include media-breakpoint-down(xl){
                margin-bottom: 8px;
                font-size: 20px;
            }
            @include media-breakpoint-down(md){
                font-size: 16px;
            }

            @media (max-width: 375px) {
                br{
                    display: none;
                }
            }

            &--small {
                line-height: 130%;
                width: 100%;

                @include media-breakpoint-up(xl){
                    font-size: rems(20px);
                }
            }
            &--big {
                line-height: 1.2;
                letter-spacing: -0.02em;

                @include media-breakpoint-up(xl){
                    font-size: 28px;
                    margin-bottom: 24px;
                }
            }
        }
        &-text{
            @include media-breakpoint-up(md){
                font-size: 16px;
            }
            @include media-breakpoint-down(md){
                font-size: 14px;
            }

            &>*:last-child {
                margin-bottom: 0;
            }

            li + li {
                margin-top: 16px;
            }
        }
    }

    &__btn {
        margin-top: 45px;

        @include media-breakpoint-down(sm) {
            .btn {
                width: 100%;
            }
        }
    }

    &--border-bottom{
        border-bottom: 2px solid $gray-200;

        @include media-breakpoint-up(lg){
            padding-bottom: 104px;
        }
        @include media-breakpoint-between(md, lg){
            padding-bottom: 64px;
        }
        @include media-breakpoint-down(md){
            padding-bottom: 56px;
        }
    }
}