.links-block{
    & + & {
        margin-top: 40px;
    }
}

.table-rows{
    overflow-x: auto;
    padding-bottom: 20px;
    margin-inline: -24px;
    padding-inline: 24px;

    .row{
        flex-wrap: nowrap;
    }

    @include media-breakpoint-down(md){
        .row{
            min-width: 700px;
        }
        hr{
            min-width: 700px;
        }
    }
}

.gray-section{
    background-color: $gray-100;

    @include media-breakpoint-up(lg) {
        padding-block: 104px 94px;
    }

    @include media-breakpoint-between(md, lg) {
        padding-block: 64px;
    }

    @include media-breakpoint-down(md) {
        padding-block: 56px;
    }
}