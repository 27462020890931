@import './vacancy-card';

.vacancies-section {
    &__description {
        margin-bottom: 56px;

        &:not(&--no-accent) {

            h2,
            .h2 {
                b {
                    color: $danger;
                }
            }
        }
    }

    &__item {
        &--margin:not(:last-child) {
            margin-bottom: 8px;
        }

        &:not(&--margin):first-child {
            .vacancy-card {
                border-top: 1px solid $gray-200;
            }
        }

    }

    &__container {
        &:not(.container)>.row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__table-head {

        @include media-breakpoint-up(md) {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: $gray-600;
            margin-bottom: 24px;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &--small-margin {
        margin-top: 56px;
    }
}