.project-detail {
    margin-top: 32px;
    z-index: 2;
    position: relative;

    &__header {

        @include media-breakpoint-up(lg) {
            margin-bottom: 104px;
        }

        @include media-breakpoint-between(md, lg) {
            margin-bottom: 64px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 56px;
        }
    }

    &__date {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #8F8F8F;
        margin-bottom: 16px;
    }

    &__title {
        @include media-breakpoint-up(xl) {
            width: 70%;
            font-size: 65px;
        }

        @include media-breakpoint-between(lg, xl) {
            width: 80%;
            font-size: 50px;
        }

        @include media-breakpoint-between(md, lg) {
            font-size: 50px;
        }

        @media (max-width: 425px) {
            font-size: 25px;
        }

        @media (max-width: 320px) {
            font-size: 20px;
        }
    }

    &__picture {
        img {
            width: 100%;
        }

        margin-bottom: 40px;
    }
    &__content{
        img {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                margin-block: 56px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }

        li+li {
            margin-top: rems(16px);
        }

        p {
            margin-bottom: 32px;
        }

        h2 {
            margin-top: 56px;
            margin-bottom: 32px;
        }

        h3 {
            margin-top: 56px;
            margin-bottom: 24px;
        }

        h4 {
            margin-block: 32px;
        }

        h5,
        .h5,
        h6,
        .h6 {
            margin-block: 24px;
        }

        ul,
        ol {
            margin-bottom: 32px;
            font-size: rems(16px);
        }

        blockquote{
        
            h4, .h4{
                margin-top: 30px;
                margin-bottom: 13px;
            }
        }
    }
}